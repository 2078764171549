import { connect } from 'react-redux';
import { change } from 'redux-form';
import { getBalanceWithProductSymbol } from 'apex-web/lib/helpers/productHelper';
import {
  getPriceDataForPresetValuesSelector,
  isBuySideBuySellFormSelector
} from 'apex-web/lib/redux/selectors/buySellSelectors';

import BuySellPresetValuesRowsComponent from './BuySellPresetValuesRowsComponent';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';

var mapStateToProps = function mapStateToProps(state) {
  var form = state.form;

  var formObj = form.buySell || { values: {} };
  var dealValues = getPriceDataForPresetValuesSelector(
    state,
    formObj.values.side
  );
  var selectedInstrument = selectedInstrumentSelector(state);

  var presetValues = dealValues.map(function(deal) {
    return {
      key: deal.price,
      amountRadio: deal.price.toString(),
      value1: getBalanceWithProductSymbol(
        selectedInstrument.Product2Symbol,
        deal.price
      ),
      value2: deal.quantity
    };
  });

  return {
    isBuySide: isBuySideBuySellFormSelector(state),
    dealValues: presetValues
  };
};

var mapDispatchToProps = {
  setAmount: function setAmount(amount) {
    return change('buySell', 'amountRadio', amount);
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuySellPresetValuesRowsComponent);
