import { connect } from 'react-redux';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import SimpleSignupFormComponent from './SimpleSignupFormComponent';
import {
  signup,
  clearSignupError
} from 'apex-web/lib/redux/actions/signupActions';
import {
  authSuccess,
  authError,
  redirectFromLogin
} from 'apex-web/lib/redux/actions/authActions';
import { preprocessErrorTextForReduxForm } from 'apex-web/lib/helpers/errorTextFormatter';
import get from 'lodash/get';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import apex from 'apex-web/lib/apex';
import { signUp2uChat } from '../../../redux/action/signUp2uChatActions';

let getQueryParams = () => {
  let matchArray = window.location.href.match(/\?(.*)/);
  if (matchArray) {
    return matchArray[1].split('&').reduce((acc, p) => {
      let ps = p.split('=');
      if (ps.length != 2) return acc;
      return Object.assign(acc, { [ps[0]]: ps[1] });
    }, {});
  }
  return {};
};

var SimpleSignupFormForm = reduxForm({
  form: 'signup',
  onSubmit: function onSubmit(payload, dispatch) {
    let queryParams = getQueryParams();
    let { groupId, email } = queryParams;
    let { externalId } = payload;
    if (typeof externalId === 'undefined')
      payload.externalId = externalId = queryParams['externalId'];
    email = payload.email;
    return new Promise((resolve, reject) => {
      dispatch(
        signUp2uChat(
          {
            externalId: payload.externalId,
            groupId,
            passwordHash: payload.password,
            firstName: payload['GovID_FirstName'],
            lastName: payload['GovID_LastName'],
            country: payload['GovID_Country'],
            email
          },
          resolve,
          reject
        )
      );
    });
  }
})(props => {
  return (
    <SimpleSignupFormComponent {...{ ...props, useEmailAsUsername: true }} />
  );
});

var selector = formValueSelector('signup');

var mapStateToProps = function mapStateToProps(state, ownProps) {
  let queryParams = getQueryParams();
  return Object.assign(
    {
      passwordValue: selector(state, 'password'),
      errorMsg: get(state, 'form.signup.error', false),
      done: !!state.signup.userId,
      externalId: queryParams['externalId']
    },
    ownProps
  );
};

var mapDispatchToProps = {
  clearSignupError: clearSignupError
};

var SimpleSignupFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleSignupFormForm);

export default SimpleSignupFormContainer;
