import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APIcon from 'apex-web/lib/components/common/APIcon';
import './FiatFormTabsComponent.css';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var bemClasses = getBEMClasses('fiat-tabs');

var FiatFormTabsComponent = function (_React$Component) {
  _inherits(FiatFormTabsComponent, _React$Component);

  function FiatFormTabsComponent() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, FiatFormTabsComponent);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = FiatFormTabsComponent.__proto__ || Object.getPrototypeOf(FiatFormTabsComponent)).call.apply(_ref, [this].concat(args))), _this), _this.onWithdrawClick = function () {
      var _this$props = _this.props,
          isSend = _this$props.isSend,
          toggleTab = _this$props.toggleTab,
          isWithdrawDisabled = _this$props.isWithdrawDisabled;

      if (isSend && !_this.isNegativeBalance() && !isWithdrawDisabled) {
        toggleTab();
      }
    }, _this.isNegativeBalance = function () {
      return _this.props.balance < 0;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(FiatFormTabsComponent, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          isSend = _props.isSend,
          toggleTab = _props.toggleTab,
          isDepositDisabled = _props.isDepositDisabled,
          isWithdrawDisabled = _props.isWithdrawDisabled;

      return React.createElement(
        'header',
        { className: bemClasses() },
        React.createElement(
          'div',
          {
            className: bemClasses('tab', {
              selected: isSend,
              disabled: isDepositDisabled
            }),
            onClick: function onClick() {
              return !isSend && !isDepositDisabled && toggleTab();
            } },
          React.createElement(APIcon, { name: 'deposit', customClass: bemClasses('tab-icon') }),
          this.context.t('Deposit')
        ),
        React.createElement(
          'div',
          {
            className: bemClasses('tab', {
              selected: !isSend,
              disabled: this.isNegativeBalance() || isWithdrawDisabled
            }),
            onClick: function onClick() {
              return _this2.onWithdrawClick();
            } },
          React.createElement(APIcon, { name: 'withdraw', customClass: bemClasses('tab-icon') }),
          this.context.t('Withdraw')
        )
      );
    }
  }]);

  return FiatFormTabsComponent;
}(React.Component);

FiatFormTabsComponent.propTypes = {
  isSend: PropTypes.bool.isRequired,
  toggleTab: PropTypes.func.isRequired,
  balance: PropTypes.number.isRequired
};

FiatFormTabsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatFormTabsComponent;