import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import config from 'apex-web/lib/config/';
import Snackbar from 'apex-web/lib/components/common/Snackbar/Snackbar';

import './APSnackbar.css';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var APSnackbar = function (_Component) {
  _inherits(APSnackbar, _Component);

  function APSnackbar() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, APSnackbar);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = APSnackbar.__proto__ || Object.getPrototypeOf(APSnackbar)).call.apply(_ref, [this].concat(args))), _this), _this.bemClasses = getBEMClasses(['ap-snackbar']), _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(APSnackbar, [{
    key: 'closeSnack',
    value: function closeSnack(snack) {
      this.props.dismissSnackByUniqueId(snack.uniqueId);
      snack.onClose && snack.onClose();
    }
  }, {
    key: 'getSnackElement',
    value: function getSnackElement(snack) {
      var _this2 = this;

      var idx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

      var timeout = snack.timeout || config.Snackbar.timeout;
      var animationTimeout = Math.round(timeout / 1000);

      setTimeout(function () {
        return _this2.closeSnack(snack);
      }, timeout);

      return React.createElement(
        'div',
        { key: idx, className: this.bemClasses('snack') },
        React.createElement(Snackbar, Object.assign({}, snack, {
          onClose: function onClose() {
            return _this2.closeSnack(_this2.props.snacks[0]);
          }
        })),
        config.Snackbar.showProgressBar && React.createElement('div', {
          className: this.bemClasses('snack-progress'),
          style: { animationDuration: animationTimeout + 's' }
        })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;
      
      return ReactDOM.createPortal(React.createElement(
        'div',
        { className: this.bemClasses() },
        config.Snackbar.showMultiple && this.props.snacks.map(function (snack, idx) {
          return _this3.getSnackElement(snack, idx);
        }),
        !config.Snackbar.showMultiple && this.props.snacks[0] && this.getSnackElement(this.props.snacks[0])
      ), document.body);
    }
  }]);

  return APSnackbar;
}(Component);

APSnackbar.defaultProps = {
  timeout: config.Snackbar.timeout,
  snacks: []
};
APSnackbar.propTypes = {
  timeout: PropTypes.number,
  snacks: PropTypes.arrayOf(PropTypes.shape({
    timeout: PropTypes.number,
    text: PropTypes.string.isRequired,
    textVars: PropTypes.object,
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['info', 'success', 'warning']),
    customClass: PropTypes.string,
    onClose: PropTypes.func
  })).isRequired
};


export default APSnackbar;