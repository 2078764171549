import React from 'react';
import PropTypes, { func } from 'prop-types';
import { Link } from 'react-router-dom';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import { getBEMClasses } from '../helpers/cssClassesHelper';

import SignupFormContainer from '../components/SimpleSignupForm';
import styled from 'styled-components';
import Logo from '../images/custom/logo-white-cropped.png';

import './SignupPage.css';

const baseClasses = getBEMClasses('standalone-form');
const signupPageClasses = getBEMClasses('signup-page');

const Bg = styled.div`
  min-height: 120vh;
  background: white;
`;

const LoginSection = styled.section`
  min-height: calc(100vh - 129px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

const campaign_title_list = {
  untt: {
    h1: 'Thank you for purchasing UNTT',
    h2: 'Please create a GreenX account now'
  }
};

export const SignupFormComponent = (props, context) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const campaign_url_param = urlParams.get('campaign');
  console.log('campaign_url_param', campaign_url_param);

  var campaign_h1 = '';
  var campaign_h2 = '';

  if (campaign_url_param != null) {
    if (campaign_title_list[campaign_url_param] != undefined) {
      campaign_h1 = campaign_title_list[campaign_url_param].h1;
      campaign_h2 = campaign_title_list[campaign_url_param].h2;
    }
  }

  const ShowCampaignTitle = () => {
    if (campaign_url_param != null) {
      return (
        <div className="d-flex flex-column m-auto justify-content-center my-3">
          <h1 className="text-center">{context.t(campaign_h1)}</h1>
          <h2 className="loginSto_news text-center">
            {context.t(campaign_h2)}
          </h2>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <React.Fragment>
      <div className="login-page signup-form">
        <div className="row align-items-center">
          <div className="col-lg-6 col-brand">
            <img className="login-logo" src={Logo} />
            <a
              href="https://www.green-x.io/"
              target="_blank"
              className="visit-website-link">
              {context.t('Visit Our Website')}
            </a>
            <div className="campaign-title-section my-4">
              <ShowCampaignTitle />
            </div>
          </div>
          <div className="col-lg-6 col-form">
            <div className="login-form-container">
              <div className="mobile-logo my-4">
                <img src={Logo} className="login-logo" />
              </div>
              <div className="mobile-campaign-title-section">
                <ShowCampaignTitle />
              </div>
              <div className="login__form__main">
                <div className="login-signup-toggle">
                  <Link to="/login">{context.t('Log In')}</Link>
                  <Link className="active" to="/signup">
                    {context.t('Sign Up')}
                  </Link>
                </div>
                <SignupFormContainer />
                {/* <div className="cant__login">
                  <ul>
                    <li>
                      <Link to="/login">
                        {context.t('Already have an account? Sign in')}
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  SignupFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
