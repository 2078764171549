import React from 'react';
import resize from 'apex-web/lib/hocs/resize';
import { connect } from 'react-redux';
// import InstrumentRowComponent from 'apex-web/lib/components/InstrumentRow/InstrumentRowComponent';
import InstrumentRowComponent from '../../components/InstrumentRow/InstrumentRowComponent';
import ConfirmLimitOrder from 'apex-web/lib/components/ConfirmLimitOrderModal/ConfirmLimitOrderModalContainer';
import OrderHistoryContainer from 'apex-web/lib/components/OrderHistoryComponents';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
// import 'apex-web/lib/layouts/TradingLayout/TradingLayout.css';
import './TradingLayout.css';
import ExchangePageSize from './ExchangePageSize';
import ExchangeCharts from './ExchangeCharts';
import Navbar from '../../components/NewNav';
import config from '../../config';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import instrumentPositionSelectors from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import L from 'lodash';
import { objectExpression } from '@babel/types';
import { formatToThousands } from 'apex-web/lib/helpers/numberFormatter';
import { Link } from 'react-router-dom';

const tradingLayoutClasses = getBEMClasses('trading-layout');

class ExchangePage extends React.Component {
  componentDidMount() {

    document.title = "Green-X Trade"

    document.querySelector('.popover').classList.remove('popover');

  }

  render() {
    const {
      OrderHistoryData: { usePagination },
      TradingLayout: { maxLines }
    } = config;

    const orderHistoryConfig = {
      usePagination,
      maxLines,
      filterMode: 'selectedInstrument'
    };

    return (
      <React.Fragment>
        {/* <Navbar /> */}
        <div className={tradingLayoutClasses('container')}>
          {/* <div className="kyc-msg">
            <span>
              Dear user, please ensure that you have completed your KYC
              successfully before trade.
            </span>
            <span className="kyc-link">
              You may complete your KYC{' '}
              <Link to="/settings/verification-level" className="kyc-link-url">
                here
              </Link>.
            </span>
          </div> */}
          <InstrumentRowComponent
            level1={this.props.getLevel1(
              this.props.selectedInstrument['InstrumentId']
            )}
            positions={this.props.positions}
            tickers={this.props.tickers}
          />
          <div className={tradingLayoutClasses('body')}>
            <div className={tradingLayoutClasses('chart-container')}>
              <ExchangeCharts
                width={this.props.width}
                symbol={this.props.formatSymbol(
                  this.props.selectedInstrument.Symbol
                )}
              />
            </div>
            <OrderHistoryContainer
              filterMode="selectedInstrument"
              config={orderHistoryConfig}
            />
            <ExchangePageSize height={this.props.height} />
          </div>
          <ConfirmLimitOrder />
        </div>
      </React.Fragment>
    );
  }
}

let pipe = (...fns) => arg => fns.reduce((b, a) => a(b), arg);
let mapStateToProps = state => ({
  positions: instrumentPositionSelectors(state),
  getLevel1: instrumentID => L.get(state.apexCore, ['level1', instrumentID]),
  selectedInstrument: selectedInstrumentSelector(state),
  formatSymbol: symbol => {
    if (symbol === 'USDTUSD') {
      return `KRAKEN:${symbol}`;
    } else if (symbol === 'RVNUSDT') {
      return `BINANCE:${symbol}`;
    } else {
      return `BITSTAMP:${symbol}`;
    }
  },
  tickers: L.last(state.tickers.tickers)
});
export default pipe(
  connect(mapStateToProps),
  resize
)(ExchangePage);
