import React from 'react';
import PropTypes from 'prop-types';
import APIconButton from 'apex-web/lib/components/common/APIconButton/APIconButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import './WalletNavItems.css';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var fiatNavItemsClasses = getBEMClasses('wallet-nav-item');

var FiatNavItems = function (_React$Component) {
  _inherits(FiatNavItems, _React$Component);

  function FiatNavItems() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, FiatNavItems);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = FiatNavItems.__proto__ || Object.getPrototypeOf(FiatNavItems)).call.apply(_ref, [this].concat(args))), _this), _this.onDepositClick = function () {
      if (!_this.props.isDepositDisabled) _this.onBtnClick(true);
    }, _this.onWithdrawClick = function () {
      if (!_this.props.isWithdrawDisabled) _this.onBtnClick(false);
    }, _this.onBtnClick = function (isSend) {
      var _this$props = _this.props,
          openRetailFiatSidePane = _this$props.openRetailFiatSidePane,
          product = _this$props.product,
          isDepositDisabled = _this$props.isDepositDisabled,
          isWithdrawDisabled = _this$props.isWithdrawDisabled;

      openRetailFiatSidePane(isSend, product, isDepositDisabled, isWithdrawDisabled);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(FiatNavItems, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          showIconButtonLabel = _props.showIconButtonLabel,
          isWithdrawDisabled = _props.isWithdrawDisabled,
          isDepositDisabled = _props.isDepositDisabled;

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(APIconButton, {
          iconName: 'deposit',
          action: this.onDepositClick,
          tooltipText: showIconButtonLabel ? null : this.context.t('Deposit'),
          customClass: fiatNavItemsClasses(),
          showLabel: showIconButtonLabel,
          label: this.context.t('Deposit'),
          isDisabled: isDepositDisabled
        }),
        React.createElement(APIconButton, {
          iconName: 'withdraw',
          action: this.onWithdrawClick,
          tooltipText: showIconButtonLabel ? null : this.context.t('Withdraw'),
          customClass: fiatNavItemsClasses(),
          showLabel: showIconButtonLabel,
          isDisabled: isWithdrawDisabled,
          label: this.context.t('Withdraw')
        })
      );
    }
  }]);

  return FiatNavItems;
}(React.Component);

FiatNavItems.defaultProps = {
  showIconButtonLabel: true
};

FiatNavItems.propTypes = {
  openRetailFiatSidePane: PropTypes.func.isRequired,
  product: PropTypes.shape({
    iconFileName: PropTypes.string,
    ProductFullName: PropTypes.string,
    ProductSymbol: PropTypes.string,
    ProductId: PropTypes.number
  }),
  showIconButtonLabel: PropTypes.bool,
  isWithdrawDisabled: PropTypes.bool
};

FiatNavItems.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatNavItems;