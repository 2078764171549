import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import { productsListSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import WithdrawFormComponent from './WithdrawFormComponent';
import { getWithdrawFee as _getWithdrawFee, fetchProductWithdrawTemplates, createWithdrawTicket, fetchProductWithdrawTemplate } from 'apex-web/lib/redux/actions/withdrawActions';
import store from 'apex-web/lib/redux/store';
import { closeWithdrawSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import config from 'apex-web/lib/config';

var formSelector = formValueSelector('createWithdraw');
var closeSidePane = function closeSidePane() {
  return store.dispatch(closeWithdrawSidePane());
};

var mapStateToProps = function mapStateToProps(state) {
  var products = productsListSelector(state);
  var positions = positionSelector(state);

  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
      disableWithdraw = _kycVerificationLevel.disableWithdraw;

  return {
    disableWithdraw: disableWithdraw,
    products: products,
    withdrawInfo: state.withdraw,
    positions: positions,
    amountValue: formSelector(state, 'Amount'),
    selectedProductId: formSelector(state, 'ProductId'),
    hideFees: !!config.global.hideFees,
    VerificationRequiredComponent: React.createElement(VerificationRequiredContainer, {
      disabled: disableWithdraw,
      onClick: closeSidePane
    })
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    selectWithdrawProduct: function selectWithdrawProduct(productId) {
      return dispatch(fetchProductWithdrawTemplates(productId));
    },
    selectWithdrawTemplate: function selectWithdrawTemplate(productId, templateType) {
      return dispatch(fetchProductWithdrawTemplate(productId, templateType));
    },
    getWithdrawFee: function getWithdrawFee(productId, amount) {
      return dispatch(_getWithdrawFee(productId, amount));
    },
    submitWithdraw: function submitWithdraw(payload) {
      return dispatch(createWithdrawTicket(payload));
    }
  };
};

var WithdrawFormContainer = connect(mapStateToProps, mapDispatchToProps)(WithdrawFormComponent);

export default reduxForm({
  form: 'createWithdraw',
  onSubmit: function onSubmit() {}
})(WithdrawFormContainer);