import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';
import LoginFormComponent from './LoginFormComponent';
import {
  login,
  redirectFromLogin,
  authSuccess,
  authError,
  AUTH_2FA_REQUIRED,
  ENABLE_2FA_REQUIRED,
} from 'apex-web/lib/redux/actions/authActions';
import path from '../../../helpers/path';
import { withRouter } from 'react-router';
import config from '../../../config';
import { getLoginFormGateway } from '../../../helpers/wsHelper';
import { initApex, setGateway } from '../../../apex';
import {
  connectionClosed,
  connectionClosedCount
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { loadStartupData } from 'apex-web/lib/redux/actions/appActions';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';
import apex from 'apex-web/lib/apex';
import {conditionalLogout} from '../../../redux/action/customAuthActions'

var globalSiteName = config.global.siteName,
  LoginForm = config.LoginForm,
  _config$SignupForm = config.SignupForm,
  useEmailAsUsername = _config$SignupForm.useEmailAsUsername,
  active = _config$SignupForm.active;

var gateway = getLoginFormGateway();

var siteName =
  LoginForm && LoginForm.siteName ? LoginForm.siteName : globalSiteName;

var mapStateToProps = function mapStateToProps(state) {
  return {
    errorMsg: state.auth.errorMsg,
    isConnected: state.wsConnection.isConnected,
    useEmailAsUsername: useEmailAsUsername,
    active: active,
    siteName: siteName,
    gateway: gateway,
    formGateway: formValueSelector('login')(state, 'gateway'),
    externalId: getQueryParams()['externalId']
  };
};

let getQueryParams = () => {
  let matchArray = window.location.href.match(/\?(.*)/);
  if (matchArray) {
    return (
      matchArray[1].split('&').reduce((acc, p) => {
        let ps = p.split('=');
        if (ps.length != 2)
          return acc;
        return Object.assign(acc, {[ps[0]]: ps[1]});
      }, {})
    );
  }
  return {};
}

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  let redirect = url => {
    window.location.href = url;
    dispatch(redirectFromLogin(url))
  };
  let showMessage = msg => dispatch => (
    dispatch(authError({Authenticated: false, errormsg: msg}))
  );
  return {
    showMessage: msg => dispatch(showMessage(msg)),
    resetForm: function resetForm() {
      return dispatch(change('login', 'gateway', ''));
    },
    doSubmit: function doSubmit(payload, isConnected) {
      let queryParams = getQueryParams();
      let {groupId} = queryParams;
      let {externalId, password} = payload;
      if (typeof externalId === 'undefined')
        payload.externalId = externalId = queryParams['externalId'];
      dispatch(redirectFromLogin('/'));
      let deferred = (function () {
        let resolve, reject;
        return {
          promise: new Promise((r0, r1) => {resolve = r0; reject = r1}),
          resolve, reject
        };
      })();
      return (
        Promise.resolve({userName: externalId})
          .then(({userName}) => {
            if (typeof userName === 'string') {
              return (
                new Promise((resolve, reject) => {
                  dispatch(conditionalLogout(resolve, reject, () => deferred.promise));
                })
                  .then(deferred.resolve)
                  .then(() => apex.connection.WebAuthenticateUser({userName, password}))
                  .then(resp => {
                    let {UserId, SessionToken, Authenticated, errormsg} = resp;
                    if (Authenticated) {
                      if (typeof UserId === 'number' && typeof SessionToken === 'string') {
                        localStorage.setItem('externalId', externalId);
                        localStorage.setItem('token', SessionToken);
                        dispatch(showMessage('Login succeeds. Redirecting...'));
                        dispatch(authSuccess(resp));
                        return Promise.resolve();
                      }
                      throw new Error('Login fails');
                    } else {
                      dispatch(showMessage(errormsg));
                    }
                  })
              );
            }
            throw new Error(`This 2U Chat user doesn't exist.`);
          })
          .catch(reason => (
            new Promise(r => setTimeout(r, 2e3)).then(deferred.resolve),
            dispatch(showMessage(`${reason}`))
          ))
      );
    }
  };
};

var LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent);

var LoginFormContainerForm = reduxForm({
  form: 'login',
  onSubmit: function onSubmit() {}
})(LoginFormContainer);

export default withRouter(LoginFormContainerForm);
