/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ScriptLoader from 'apex-web/lib/helpers/scriptLoader';

var baseClasses = getBEMClasses('withdraw-form');

var TrustpayWithdrawWorkflowComponent = function TrustpayWithdrawWorkflowComponent(_ref) {
  var url = _ref.url;

  if (url) {
    return React.createElement(
      'div',
      { className: baseClasses('trustpay-form') },
      React.createElement('form', {
        action: location.href,
        className: 'paymentWidgets',
        'data-brands': 'VISA MASTER AMEX'
      }),
      React.createElement(ScriptLoader, { url: url })
    );
  }
  return null;
};

TrustpayWithdrawWorkflowComponent.defaultProps = {
  url: ''
};

TrustpayWithdrawWorkflowComponent.propTypes = {
  url: PropTypes.string
};

export default TrustpayWithdrawWorkflowComponent;