import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APSelect from 'apex-web/lib/components/common/APSelect';
import WithdrawTemplateFormContainer from '../WithdrawTemplateForm/WithdrawTemplateFormContainer';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getTotalRender, getTotalWithdrawValue, getAvailableBalance } from 'apex-web/lib/helpers/withdrawHelper';

import './WithdrawFormComponent.css';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var baseClasses = getBEMClasses('withdraw-form');
var slidePaneClasses = getBEMClasses('slide-pane');

var WithdrawFormComponent = function (_React$Component) {
  _inherits(WithdrawFormComponent, _React$Component);

  function WithdrawFormComponent(props) {
    _classCallCheck(this, WithdrawFormComponent);

    var _this = _possibleConstructorReturn(this, (WithdrawFormComponent.__proto__ || Object.getPrototypeOf(WithdrawFormComponent)).call(this, props));

    _this.getInitialState = function () {
      return { position: null, product: {}, errorMessage: '' };
    };

    _this.selectProduct = function (productId) {
      var _this$props = _this.props,
          selectWithdrawProduct = _this$props.selectWithdrawProduct,
          products = _this$props.products;

      var product = products.find(function (product) {
        return product.ProductId === productId;
      });

      if (product) {
        var position = _this.getPosition(product.Product);

        selectWithdrawProduct(product.ProductId);
        _this.setState({ position: position, product: product });
      } else {
        _this.setState(_this.getInitialState());
      }
    };

    _this.getPosition = function (productId) {
      return _this.props.positions.find(function (position) {
        return position.ProductSymbol === productId;
      });
    };

    _this.onAmountChange = function (amount) {
      var product = _this.state.product;

      if (!isNaN(amount)) _this.props.getWithdrawFee(product.ProductId, amount);
    };

    _this.doSubmit = function (values) {
      var _this$state$position = _this.state.position,
          Amount = _this$state$position.Amount,
          Hold = _this$state$position.Hold;
      var _this$props2 = _this.props,
          _this$props2$withdraw = _this$props2.withdrawInfo,
          fee = _this$props2$withdraw.withdrawFee,
          TemplateType = _this$props2$withdraw.template.TemplateType,
          submitWithdraw = _this$props2.submitWithdraw;

      var amount = parseFloat(values.Amount);
      var balance = getAvailableBalance(Amount, Hold);

      if (amount <= balance) {
        values.Amount = getTotalWithdrawValue(balance, values.Amount, fee);
      } else {
        _this.setState({
          errorMessage: _this.context.t('You have insufficient balance')
        });
        return;
      }
      return submitWithdraw(Object.assign({}, values, {
        TemplateType: TemplateType,
        ProductId: _this.state.product.ProductId
      }));
    };

    _this.getTotal = function (balance, amount, fee) {
      var total = getTotalRender(balance, amount, fee);

      return total || total === 0 ? total : _this.context.t('Insufficient funds');
    };

    _this.getInfoText = function (value) {
      var product = _this.state.product;

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(
          'span',
          { className: baseClasses('currency') },
          formatNumberToLocale(value, product && product.Product)
        ),
        ' ',
        product && product.Product
      );
    };

    _this.state = _this.getInitialState();
    return _this;
  }

  _createClass(WithdrawFormComponent, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var product = this.props.product;

      this.selectProduct(product.ProductId);
    }
  }, {
    key: 'render',
    value: function render() {
      var _state = this.state,
          product = _state.product,
          _state$position = _state.position,
          Amount = _state$position.Amount,
          Hold = _state$position.Hold,
          errorMessage = _state.errorMessage;
      var _props = this.props,
          handleSubmit = _props.handleSubmit,
          submitting = _props.submitting,
          withdrawInfo = _props.withdrawInfo,
          amountValue = _props.amountValue,
          invalid = _props.invalid,
          selectWithdrawTemplate = _props.selectWithdrawTemplate,
          hideFees = _props.hideFees;

      var balance = getAvailableBalance(Amount, Hold);
      var total = this.getTotal(balance, amountValue, withdrawInfo.withdrawFee);
      var balanceText = this.getInfoText(balance);
      var feeText = this.getInfoText(withdrawInfo.withdrawFee);
      var totalText = this.getInfoText(total);
      var receivedText = getTotalWithdrawValue(balance, amountValue, withdrawInfo.withdrawFee);
      var showTemplateTypeSelect = withdrawInfo.templateTypes.length > 1 && !Object.keys(withdrawInfo.templateInfo).length && !withdrawInfo.isLoading;
      var templateTypes = [{ value: '', label: 'Select an option' }].concat(_toConsumableArray(withdrawInfo.templateTypes.map(function (template) {
        return {
          value: template.TemplateName,
          label: template.TemplateName
        };
      })));
      
      return React.createElement(
        'form',
        {
          onSubmit: handleSubmit(this.doSubmit),
          className: slidePaneClasses('body') },
        withdrawInfo.withdrawStatus.success ? React.createElement(
          'div',
          { className: baseClasses('success') },
          this.context.t('Your withdraw ticket was created successfully.')
        ) : React.createElement(
          'div',
          { className: baseClasses('form-body') },
          withdrawInfo.error && React.createElement(
            'div',
            { className: baseClasses('error-text') },
            this.context.t(withdrawInfo.error)
          ),
          withdrawInfo.isLoading && React.createElement(Spinner, { customClass: baseClasses }),
          showTemplateTypeSelect && React.createElement(
            React.Fragment,
            null,
            React.createElement(
              'p',
              null,
              this.context.t('Select an option to continue the withdraw process')
            ),
            React.createElement(APSelect, {
              name: 'TemplateType',
              customClass: baseClasses(),
              label: this.context.t('Options for withdraw'),
              onSelect: function onSelect(value) {
                return selectWithdrawTemplate(product.ProductId, value);
              },
              options: templateTypes
            })
          ),
          React.createElement(
            WithdrawTemplateFormContainer,
            {
              submitting: submitting,
              invalid: invalid,
              baseClasses: baseClasses,
              balance: balance,
              onAmountChange: this.onAmountChange,
              errorMessage: errorMessage },
            React.createElement(
              'div',
              { className: baseClasses('section') },
              product && React.createElement(
                React.Fragment,
                null,
                React.createElement(APLabelWithText, {
                  label: this.context.t('Balance:'),
                  text: balanceText,
                  dataTest: 'Balance:',
                  customClass: baseClasses()
                }),
                !hideFees && React.createElement(APLabelWithText, {
                  label: this.context.t('Fee:'),
                  text: feeText,
                  dataTest: 'Fee:',
                  customClass: baseClasses()
                }),
                React.createElement(APLabelWithText, {
                  label: this.context.t('Total:'),
                  text: totalText,
                  dataTest: 'Total:',
                  customClass: baseClasses()
                }),
                React.createElement(APLabelWithText, {
                  label: this.context.t('Received:'),
                  text: receivedText,
                  dataTest: 'Received:',
                  customClass: baseClasses()
                })
              )
            )
          )
        )
      );
    }
  }]);

  return WithdrawFormComponent;
}(React.Component);

WithdrawFormComponent.defaultProps = {
  withdrawInfo: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    TemplateForm: {},
    withdrawStatus: {},
    getWithdrawFee: function getWithdrawFee() {}
  }
};

WithdrawFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  getWithdrawFee: PropTypes.func,
  submitting: PropTypes.bool,
  selectWithdrawProduct: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    Product: PropTypes.string,
    ProductId: PropTypes.number
  })).isRequired,
  withdrawInfo: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    TemplateForm: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    withdrawStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  }),
  VerificationRequiredComponent: PropTypes.element
};

WithdrawFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default WithdrawFormComponent;