import React from 'react';
import PropTypes from 'prop-types';
import APIcon from 'apex-web/lib/components/common/APIcon';

var BuySellTabsComponent = function BuySellTabsComponent(props, context) {
  var baseClasses = props.baseClasses,
    clearForm = props.clearForm,
    setBuyValue = props.setBuyValue,
    setSellValue = props.setSellValue,
    isBuySide = props.isBuySide,
    themeModifier = props.themeModifier;

  return React.createElement(
    'div',
    { className: baseClasses('tab-wrapper') },
    React.createElement(
      'div',
      {
        className: baseClasses('tab', {
          'buy-selected': isBuySide
        }),
        onClick: function onClick() {
          clearForm();
          setBuyValue();
        }
      },
      React.createElement(APIcon, {
        name: 'buy',
        classModifiers: 'big',
        customClass: baseClasses('icon')
      }),
      context.t('Buy')
    ),
    React.createElement('div', {
      className: baseClasses('vertical-divider', themeModifier)
    }),
    React.createElement(
      'div',
      {
        className: baseClasses('tab', {
          'sell-selected': !isBuySide
        }),
        onClick: function onClick() {
          clearForm();
          setSellValue();
        }
      },
      React.createElement(APIcon, {
        name: 'sell',
        classModifiers: 'big',
        customClass: baseClasses('icon')
      }),
      context.t('Sell')
    )
  );
};

BuySellTabsComponent.propTypes = {
  baseClasses: PropTypes.func.isRequired,
  setBuyValue: PropTypes.func.isRequired,
  setSellValue: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  isBuySide: PropTypes.bool.isRequired,
  themeModifier: PropTypes.string
};

BuySellTabsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BuySellTabsComponent;
