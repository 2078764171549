import { connect } from 'react-redux';
import { fetchProductWithdrawTemplate } from 'apex-web/lib/redux/actions/withdrawActions';
import HyphenWithdrawWorkflowComponent from './HyphenWithdrawWorkflowComponent';

var mapStateToProps = function mapStateToProps(state) {
  return {
    template: state.withdraw.template,
    productId: state.withdraw.product,
    templateType: state.withdraw.templateType
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getBankInfoForm: function getBankInfoForm(productId, templateType) {
      return dispatch(fetchProductWithdrawTemplate(productId, templateType, {
        UpdateBankData: true
      }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HyphenWithdrawWorkflowComponent);