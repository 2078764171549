import React from 'react';
import VISA from '../images/custom/visa.svg';
import MASTER from '../images/custom/master.svg';
import OfferImg from '../images/custom/Offer-img.png';

const PrepaidCardsComponent = props => {
  // props.avaliable will show if account enough to pay for this card

  const renderCardBrand = brand => {
    if (brand === 'VISA') {
      return VISA;
    } else {
      return MASTER;
    }
  };
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
      <div className="card job-box rounded shadow border-1 overflow-hidden">
        <div className="border-bottom">
          <div className="position-relative">
            <img src={OfferImg} className="img-fluid" alt="" />
            <div className="job-overlay bg-white" />
          </div>
          <h5 className="mb-0 position text-light">{`${
            props.type
          } Prepaid Card`}</h5>
          <ul className="list-unstyled head mb-0">
            <li className="badge badge-success badge-pill">USD</li>
          </ul>
        </div>

        <div className="card-body content position-relative bg-dark">
          <div className="firm-logo rounded-circle shadow bg-light text-center">
            <img
              src={renderCardBrand(props.type)}
              className="avatar avatar-md-sm"
              alt=""
            />
          </div>
          <div className="company-detail text-center mt-3">
            <h5 className="mb-0 text-light company-name">{props.price}</h5>
            <p className="text-muted">USD</p>
          </div>
          <ul className="job-facts list-unstyled">
            <li className="list-inline-item text-muted mb-2">
              You must select your card within 4 months from the date these
              instructions are sent via email. So make sure to keep an eye on
              your inbox.
            </li>
            <li className="list-inline-item text-muted mb-2">
              Once the card is redeemed, you will have 7 months to use the
              funds.
            </li>
            <li className="list-inline-item text-muted">
              Please note that currency conversion fees will be applied if the
              merchant settles in a currency other than USD.
            </li>
          </ul>
          {props.avaliable ? (
            <button
              href="page-job-detail.html"
              className="btn-pp btn-outline-primary btn-block"
              onClick={() => props.submitWithdraw()}>
              Withdraw Now
            </button>
          ) : (
            <button
              className="btn-pp btn-outline-primary btn-block"
              disabled={true}>
              Insufficient Funds
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrepaidCardsComponent;
