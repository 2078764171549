import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { showTemplateSelect } from 'apex-web/lib/helpers/withdrawHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import DepositTemplateFormContainer from 'apex-web/lib/components/DepositSidePaneComponents/DepositTemplateFormContainer';
import DepositWorkflowsContainer from 'apex-web/lib/components/DepositSidePaneComponents/DepositWorkflows/DepositWorkflowsContainer';
import classnames from 'classnames';
import APSelect from 'apex-web/lib/components/common/APSelect';

import './FiatDepositFormComponent.css';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var baseClasses = getBEMClasses('fiat-deposit-form');
var slidePaneClasses = getBEMClasses('fiat-slide-pane');

var FiatDepositFormComponent = function (_React$Component) {
  _inherits(FiatDepositFormComponent, _React$Component);

  function FiatDepositFormComponent() {
    _classCallCheck(this, FiatDepositFormComponent);

    return _possibleConstructorReturn(this, (FiatDepositFormComponent.__proto__ || Object.getPrototypeOf(FiatDepositFormComponent)).apply(this, arguments));
  }

  _createClass(FiatDepositFormComponent, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _props = this.props,
          ProductId = _props.product.ProductId,
          selectDepositProduct = _props.selectDepositProduct;

      selectDepositProduct(ProductId);
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.initialize(Object.assign({}, this.props.product));
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          disableDeposit = _props2.disableDeposit,
          deposit = _props2.depositInfo,
          _props2$depositInfo = _props2.depositInfo,
          templateTypes = _props2$depositInfo.templateTypes,
          isLoading = _props2$depositInfo.isLoading,
          error = _props2$depositInfo.error,
          depositInfo = _props2$depositInfo.depositInfo,
          product = _props2.product,
          handleSubmit = _props2.handleSubmit,
          submitting = _props2.submitting,
          selectDepositTemplate = _props2.selectDepositTemplate,
          verificationOnClick = _props2.verificationOnClick;

      var showTemplateTypeSelect = showTemplateSelect(deposit);
      var templateTypeOptions = [{ value: '', label: 'Select an option' }].concat(_toConsumableArray(templateTypes.map(function (template) {
        return {
          value: template.TemplateName,
          label: template.TemplateName
        };
      })));
      var useTemplateDepositWorkflow = depositInfo && !depositInfo.length;

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(DepositWorkflowsContainer, {
          onSubmit: handleSubmit,
          submitting: submitting
        }),
        isLoading ? React.createElement(Spinner, { customClass: baseClasses }) : React.createElement(
          React.Fragment,
          null,
          error && React.createElement(
            'p',
            { className: baseClasses('error') },
            this.context.t(error)
          ),
          useTemplateDepositWorkflow && React.createElement(
            'form',
            {
              className: classnames(slidePaneClasses('body'), baseClasses('body')),
              onSubmit: handleSubmit },
            React.createElement(
              'div',
              { className: baseClasses('form-body') },
              React.createElement(
                'div',
                { className: baseClasses('frame') },
                disableDeposit ? React.createElement(VerificationRequiredContainer, {
                  disabled: disableDeposit,
                  onClick: verificationOnClick
                }) : React.createElement(
                  React.Fragment,
                  null,
                  showTemplateTypeSelect && React.createElement(APSelect, {
                    name: 'TemplateType',
                    customClass: baseClasses(),
                    label: this.context.t('Select an option to continue the deposit process'),
                    onSelect: function onSelect(value) {
                      return selectDepositTemplate(product.ProductId, value);
                    },
                    options: templateTypeOptions
                  }),
                  React.createElement(DepositTemplateFormContainer, {
                    submitting: submitting,
                    baseClasses: baseClasses
                  })
                )
              )
            )
          )
        )
      );
    }
  }]);

  return FiatDepositFormComponent;
}(React.Component);

FiatDepositFormComponent.defaultProps = {
  deposit: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    depositInfo: {},
    depositStatus: {}
  }
};

FiatDepositFormComponent.propTypes = {
  disableDeposit: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  showSnack: PropTypes.func,
  deposit: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    product: PropTypes.number,
    depositInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    depositStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  }),
  verificationOnClick: PropTypes.func
};

FiatDepositFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatDepositFormComponent;