import React from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APLogo from 'apex-web/lib/components/common/APLogo/APLogo';
import PageHeaderNavComponent from '../../components/PageHeader/PageHeaderNavComponent';
import UserSummaryContainer from 'apex-web/lib/components/UserSummary/UserSummaryContainer';
import './PageHeaderLayout.css';
import './UserSummaryOverrides.css';

const pageHeaderClasses = getBEMClasses('page-header');

const MainNavItems = [
  { text: 'Dashboard', icon: 'dashboard', path: '/dashboard' },
  { text: 'Wallets', icon: 'wallet', path: '/wallets' },
  { text: 'Buy & Sell', icon: 'buy-sell', path: '/buy-sell' },
  { text: 'Credit Card', icon: 'cardPurchase', path: '/credit-card' },
  // { text: 'Cash Out', icon: 'cardPurchase', path: '/cash-out' },
  // { text: 'Verification', icon: 'user', path: '/settings/verification-level' },
  { text: 'Profile', icon: 'user', path: '/settings/verification-level' },
  { text: 'Exchange', icon: 'trading', path: '/exchange' }

  // { text: 'Components', icon: 'star', path: '/component' },
  // { text: 'Balances', icon: 'balance', path: '/margin-balances' },
  // { text: 'Digital Assets', icon: 'buy-sell', path: '/digital-assets' },
];

const PageHeaderComponent = () => {
  return (
    <React.Fragment>
      <div className={pageHeaderClasses('container')}>
        <div className={pageHeaderClasses('content-wrapper')}>
          <div className={pageHeaderClasses('header-item', 'logo')}>
            <APLogo customClass={pageHeaderClasses('logo')} linkTo="/" />
          </div>
          <PageHeaderNavComponent navItems={MainNavItems} />
          <div className={pageHeaderClasses('right-panel')}>
            <UserSummaryContainer customClass="page-header-user-summary" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageHeaderComponent;
