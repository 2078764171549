import { connect } from 'react-redux';
import ReceiveComponent from './ReceiveComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { fetchProductDepositTemplate } from 'apex-web/lib/redux/actions/depositActions';

var mapStateToProps = function mapStateToProps(state) {
  // console.log(state)
  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
    disableDeposit = _kycVerificationLevel.disableDeposit;

  return {
    disableDeposit: disableDeposit,
    deposit: state.deposit,
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    verificationOnClick: function verificationOnClick() {
      return dispatch(closeSidePane());
    },
    fetch_Product_Deposit_Template: function fetch_Product_Deposit_Template(Product_ID, account_provider_ID) {
      return dispatch(fetchProductDepositTemplate(Product_ID, account_provider_ID))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveComponent);