import {_regeneratorRuntime, _asyncToGenerator} from '../../helpers/regenerator'
import {
  authSuccess,
  authError,
  redirectFromLogin,
} from 'apex-web/lib/redux/actions/authActions';
import { showSnack } from "apex-web/lib/redux/actions/snackbarActions";
import apex from 'apex-web/lib/apex';

let signUp2uChat = (payload, resolve, reject) => {
  let action = _asyncToGenerator(_regeneratorRuntime.mark(function signUp(dispatch) {
    let redirect = url => {
      window.location.href = url;
      dispatch(redirectFromLogin(url))
    };
    return _regeneratorRuntime.wrap(context => {
      for (;;) {
        switch (context.prev = context.next) {
          case 0:
            context.next = 1;
            return dispatch(redirectFromLogin('/'));
          case 1:
            context.next = 2;
            return new Promise(r => setImmediate(r));
          case 2:
            context.next = 3;
            return (
              Promise.resolve(payload)
                .then(payload => (
                  fetch(`https://card.cryptosx.io/greenx-sync/api/timeex/sign-up`, {
                    method: 'POST',
                    headers: {
                      'content-type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                  })
                    .then(_ => _.json())
                    .then(({error, payload}) => {
                      if (!error) {
                        return payload;
                      }
                      if (typeof payload === 'string')
                        throw new Error(payload);
                      throw new Error(`The registration fails.`);
                    })
                    .then(_ => Promise.all([
                      _,
                      apex.connection.WebAuthenticateUser({userName: payload.externalId, password: payload.passwordHash}),
                    ]))
                ))
            );
          case 3:
            let [{kyc}, resp] = context.sent;
            context.next = 4;
            return (
              Promise.resolve()
                .then(() => {
                  let {UserId, SessionToken, Authenticated, errormsg} = resp;
                  if (Authenticated && typeof UserId === 'number' && typeof SessionToken === 'string') {
                    localStorage.setItem('externalId', payload.externalId);
                    localStorage.setItem('token', SessionToken);
                    dispatch(authSuccess(resp));
                  }
                })
                .catch(console.error)
                .then(() => {
                  if (typeof kyc === 'object') {
                    let {redirectUrl} = kyc;
                    if (typeof redirectUrl === 'string') {
                      redirect(redirectUrl);
                      return;
                    }
                  }
                  throw new Error(`The kyc initialization fails.`);
                })
                .catch(reason => (
                  dispatch(showSnack({id: 'error', text: `${reason}`}))
                ))
                .then(resolve, reject)
            );
          case 4:
            return context.abrupt('return', undefined);
          case 'end':
            return context.stop();
        }
      }
    }, signUp, null);
  }));
  return (...args) => action.apply(this, args);
};

export {signUp2uChat}