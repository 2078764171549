import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './PurchaseFormPage.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import { connect } from 'react-redux';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';

import logo_009_CQSE from '../pages/img/cloud-digimedia-009.png';
import logo_wenew from '../pages/img/wenew-logo.png';

const layoutClasses = getBEMClasses('purchase-form-page');

const sto_details = {
  '009': {
    title: ' Cloud DigiMedia 009 ',
    image: logo_009_CQSE,
    form_link: 'https://www.green-x.io/presale_form_009',
    session_expiry: {
      '001': {
        start: 'May 20, 2024 19:00:00',
        end: 'May 20, 2024 22:00:00',
        endTimeDisplay: '22:00:00'
      },
      '002': {
        start: 'May 20, 2024 09:00:00',
        end: 'May 20, 2024 11:00:00',
        endTimeDisplay: '11:00:00'
      },
      '003': {
        start: 'May 20, 2024 13:00:00',
        end: 'May 20, 2024 16:00:00',
        endTimeDisplay: '16:00:00'
      },
      testing: {
        start: 'May 17, 2024 00:00:00',
        end: 'May 20, 2024 00:00:00',
        endTimeDisplay: '00:00:00'
      }
    }
  },
  wenew: {
    title: ' WNW RWA ',
    image: logo_wenew,
    form_link: 'https://www.green-x.io/presale_form_wenew',
    session_expiry: {
      '001': {
        start: 'May 21, 2024 12:00:00',
        end: 'May 21, 2024 15:00:00',
        endTimeDisplay: '15:00:00'
      },
      '002': {
        start: 'May 21, 2024 15:00:00',
        end: 'May 21, 2024 18:00:00',
        endTimeDisplay: '18:00:00'
      },
      testing: {
        start: 'May 17, 2024 00:00:00',
        end: 'May 21, 2024 18:00:00',
        endTimeDisplay: '18:00:00'
      }
    }
  }
};

const PurchaseFormPage = (props, context) => {
  var sto = '';
  var source = { url: '', title: 'Presale Order Form', fullUrl: '' };

  const [showForm, setShowForm] = useState(true); // to control show/hide form
  const [sessionStatus, setSessionStatus] = useState({
    datetime: '',
    message: ''
  }); // display respective message
  const [countdown, setCountdown] = useState(''); // countdown to start time
  const [endCountdown, setEndCountdown] = useState(''); // countdown to end time
  const [CampaignStatus, setCampaignStatus] = useState('');

  React.useEffect(() => {
    props.getUserInfo();
    props.getUserConfig();

    // session validation
    let currentSession = sto_details[sto].session_expiry[sessionID];
    if (currentSession) {
      const targetStartTime = new Date(currentSession.start).getTime();
      const targetEndTime = new Date(currentSession.end).getTime();
      const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        if (currentTime >= targetStartTime && currentTime <= targetEndTime) {
          // show form when between the time range
          setCampaignStatus('after_start');
          setShowForm(true);
          setSessionStatus({
            datetime: '',
            message: ''
          });
        } else {
          // hide form when outside the timerange
          setShowForm(false);

          let sessionDateTimeStr =
            sto_details[sto].session_expiry[sessionID].start +
            ' - ' +
            sto_details[sto].session_expiry[sessionID].endTimeDisplay;
          // display message based on condition
          if (currentTime < targetStartTime) {
            setSessionStatus({
              datetime: sessionDateTimeStr,
              message: 'The Presale has not started yet.'
            });
          } else if (currentTime > targetEndTime) {
            setSessionStatus({
              datetime: sessionDateTimeStr,
              message: 'The Presale expired.'
            });
          }
        }

        if (currentTime >= targetEndTime) {
          setCampaignStatus('end');
        } else if (currentTime <= targetStartTime) {
          setCampaignStatus('before_start');
        }
      }, 500);

      const countdownInterval = setInterval(() => {
        const timeUntilStart = targetStartTime - new Date().getTime();
        if (timeUntilStart <= 0) {
          clearInterval(countdownInterval);
          setCountdown('');
          setSessionStatus({ datetime: '', message: '' });
        } else {
          const days = Math.floor(timeUntilStart / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeUntilStart / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeUntilStart / (1000 * 60)) % 60);
          const seconds = Math.floor((timeUntilStart / 1000) % 60);
          setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }
      }, 1000);

      const endTimeCountdownInterval = setInterval(() => {
        const timeUntilEnd = targetEndTime - new Date().getTime();
        if (timeUntilEnd <= 0) {
          clearInterval(endTimeCountdownInterval);
          setEndCountdown('');
          setSessionStatus({ datetime: '', message: '' });
        } else {
          const days = Math.floor(timeUntilEnd / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeUntilEnd / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeUntilEnd / (1000 * 60)) % 60);
          const seconds = Math.floor((timeUntilEnd / 1000) % 60);
          setEndCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
        clearInterval(countdownInterval);
        clearInterval(endTimeCountdownInterval);
      };
    } else {
      // hide form when session_id is invalid
      setShowForm(false);
      setSessionStatus({ datetime: '', message: 'Invalid Session' });
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  sto = urlParams.get('sto');
  var sessionID = urlParams.get('session_id');

  // set form url and append params
  source.url = sto_details[sto].form_link;
  let user_info = props.user.userInfo;
  source.fullUrl =
    source.url +
    '?username=' +
    user_info.UserName +
    '&email=' +
    user_info.Email +
    '&session_id=' +
    sessionID +
    '&language=' +
    localStorage.getItem('language');

  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />
      {showForm && (
        <div className={layoutClasses('container')}>
          {props.user.verificationLevelConfigs.Level >= 1 && (
            <>
              <div className="end-time-countdown">
                <h2 className="mb-0">
                  {context.t('Presale closes in: ')} {endCountdown}{' '}
                </h2>
              </div>
              <iframe
                className="purchase-form-iframe"
                src={source.fullUrl}
                title={source.title}
                allow="clipboard-read; clipboard-write"
              />
            </>
          )}
          {props.user.verificationLevelConfigs.Level == 0 && (
            <div className="verification-message">
              <h1 className="pb-4">
                {context.t(
                  `You must complete and pass your KYC Verification to proceed.`
                )}
              </h1>
              <Link to="/settings/verification-level" className="buy-sto-btn">
                {context.t(`Complete your KYC Verification`)}
              </Link>
            </div>
          )}
        </div>
      )}
      {!showForm && (
        <div className="display-message">
          {sessionStatus.message !== 'Invalid Session' && (
            <>
              <div>
                <img src={sto_details[sto].image} className="sto-logo" />
                <div className="message-text">
                  <div className="mb-4">
                    <p>{context.t('Welcome to the ')}</p>
                    <p className="sto-title">{sto_details[sto].title}</p>
                    <p>{context.t('Token Presale')}</p>
                  </div>
                  <p className="presale-text">
                    {context.t(
                      'The Presale will take place at the following time'
                    )}
                  </p>
                </div>
              </div>
              <div className="start-time-countdown">
                <h2 className=" mb-3">
                  {context.t('Duration: ')}
                  {sessionStatus.datetime}
                </h2>
                {CampaignStatus == 'before_start' && (
                  <>
                    <h2 className="mb-0">
                      {context.t('Presale starts in: ')}
                      {countdown}{' '}
                    </h2>
                  </>
                )}
              </div>
              <h3 className="pt-4 mt-4">
                {context.t(
                  'REWARDS: Those successfully purchased WNW tokens in the Pre-Sale will received 10 DIGau tokens (~US$20) on GreenX.'
                )}
              </h3>
            </>
          )}

          <div className="session-message mt-3">
            <h2 className="session-status mt-5">
              {CampaignStatus == 'before_start' && (
                <>{context.t('The Presale has not started yet.')}</>
              )}

              {CampaignStatus == 'end' && (
                <>{context.t('The Presale expired.')}</>
              )}
            </h2>
            {/* <p className="">ID: {sessionID}</p> */}
          </div>
        </div>
      )}

      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

PurchaseFormPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user
  };
};

var mapDispatchToProps = {
  getUserInfo,
  getUserConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(PurchaseFormPage));
