import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './PurchaseFormPage.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import { connect } from 'react-redux';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';

const layoutClasses = getBEMClasses('purchase-form-page');

const PurchaseFormPage = (props, context) => {
  var source = {
    url: 'https://www.green-x.io/preseed_form_greenx.html',
    title: 'GreenX Pre-seed Order Form',
    fullUrl: ''
  };

  React.useEffect(() => {
    props.getUserInfo();
    props.getUserConfig();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  let user_group = urlParams.get('user_group');

  // set form url and append params
  let user_info = props.user.userInfo;
  source.fullUrl =
    source.url +
    '?username=' +
    user_info.UserName +
    '&email=' +
    user_info.Email +
    '&user_group=' +
    user_group +
    '&language=' +
    localStorage.getItem('language');

  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />
      <iframe
        className="purchase-form-iframe"
        src={source.fullUrl}
        title={source.title}
        allow="clipboard-read; clipboard-write"
      />

      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

PurchaseFormPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user
  };
};

var mapDispatchToProps = {
  getUserInfo,
  getUserConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(PurchaseFormPage));
