import React from 'react';
import PropTypes from 'prop-types';
import APIconButton from 'apex-web/lib/components/common/APIconButton/APIconButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import './WalletNavItems.css';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var cryptoNavItemsClasses = getBEMClasses('wallet-nav-item');

var CryptoNavItems = function (_React$Component) {
  _inherits(CryptoNavItems, _React$Component);

  function CryptoNavItems() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CryptoNavItems);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CryptoNavItems.__proto__ || Object.getPrototypeOf(CryptoNavItems)).call.apply(_ref, [this].concat(args))), _this), _this.onSendClick = function () {
      _this.onBtnClick(true);
    }, _this.onReceiveClick = function () {
      _this.onBtnClick(false);
    }, _this.onBtnClick = function (isSend) {
      var _this$props = _this.props,
          openSendReceiveSidePane = _this$props.openSendReceiveSidePane,
          product = _this$props.product,
          isWithdrawDisabled = _this$props.isWithdrawDisabled;


      if (isSend && isWithdrawDisabled) return;
      openSendReceiveSidePane(isSend, product);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CryptoNavItems, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          showIconButtonLabel = _props.showIconButtonLabel,
          isWithdrawDisabled = _props.isWithdrawDisabled;

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(APIconButton, {
          iconName: 'send',
          action: this.onSendClick,
          tooltipText: showIconButtonLabel ? null : this.context.t('Send'),
          customClass: cryptoNavItemsClasses(),
          showLabel: showIconButtonLabel,
          isDisabled: isWithdrawDisabled,
          label: this.context.t('Send')
        }),
        React.createElement(APIconButton, {
          iconName: 'receive',
          action: this.onReceiveClick,
          tooltipText: showIconButtonLabel ? null : this.context.t('Receive'),
          customClass: cryptoNavItemsClasses(),
          showLabel: showIconButtonLabel,
          label: this.context.t('Receive')
        })
      );
    }
  }]);

  return CryptoNavItems;
}(React.Component);

CryptoNavItems.defaultProps = {
  showIconButtonLabel: true
};

CryptoNavItems.propTypes = {
  openSendReceiveSidePane: PropTypes.func.isRequired,
  product: product,
  showIconButtonLabel: PropTypes.bool
};

CryptoNavItems.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CryptoNavItems;