import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectAccount as _selectAccount } from 'apex-web/lib/redux/actions/userActions';
import { useTranslation } from 'react-i18next';
import {
  marginAccountSelector,
  defaultAccountSelector
} from 'apex-web/lib/redux/selectors/marginSelectors';
import { logout as _logout } from 'apex-web/lib/redux/actions/authActions';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  Image,
  NavItem,
  IconButton,
  IconButtonMb,
  Caret,
  Help,
  App,
  Dropdown,
  Dropdown2,
  Dropdown3,
  Dropdown4,
  DropdownMbOverlay,
  DropdownMb,
  DropdownMenuItem,
  DropdownMenuItemLink,
  DropdownMenuIcon,
  DropdownMenuIconRight,
  DropdownMenuItemSpan,
  DropdownMbClose,
  Globe
} from './NavbarElements';
import Logo from '../../images/custom/logo-white-cropped.png';
import {
  FaChartBar,
  FaRegChartBar,
  FaLandmark,
  FaLayerGroup,
  FaUser,
  FaShareSquare,
  FaUserShield,
  FaSearch,
  FaMoneyBill,
  FaUserCog,
  FaTable,
  FaWallet,
  FaRandom,
  FaRegCreditCard,
  FaLanguage,
  FaTimes
} from 'react-icons/fa';
import { noDuplicateAccountsSelector } from '../../redux/selector/noDuplicateAccountSelector';

var mapStateToProps = function mapStateToProps(state) {
  var selectedAccountId = state.user.selectedAccountId;
  // console.log("selectedAccountId", selectedAccountId)
  var marginAccount = marginAccountSelector(state);
  var accounts = noDuplicateAccountsSelector(state);
  var selectedAccount = accounts.find(function(account) {
    return account.AccountId === selectedAccountId;
  });
  if (!selectedAccount && marginAccount) {
    selectedAccount = defaultAccountSelector(state);
  }
  var selectableAccounts =
    selectedAccount === undefined
      ? accounts
      : accounts.filter(a => a.AccountId !== selectedAccount.AccountId);
  return {
    accounts: accounts,
    selectableAccounts: selectableAccounts,
    selectedAccount: selectedAccount
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    logout: function logout() {
      console.log('dispatch logout');
      return dispatch(_logout());
    },
    selectAccount: function selectAccount(payload) {
      // payload is an account
      let data = {
        AccountId: payload.AccountId,
        OMSId: payload.OMSID
      };
      return dispatch(_selectAccount(payload.AccountId, payload.OMSID));
    }
  };
};

//--------------========= Desktop navbar =========--------------
const _Navbar = props => {
  const [t, i18n] = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openMb, setOpenMb] = useState(false);

  useEffect(() => {
    const language = localStorage.getItem('language');
    console.log('language', language);
    if (language === 'zh') {
      i18n.changeLanguage('zh');
    } else if (language === 'en') {
      i18n.changeLanguage('en');
    } else if (language === 'ko') {
      i18n.changeLanguage('ko');
    }
  }, []);

  const closeAllMenu = () => {
    // i18n.changeLanguage('zh');
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpenLanguage(false);
    setOpenMb(false);
  };

  // console.log('navbar props', props);
  return (
    <React.Fragment>
      <Nav>
        <Image href="/dashboard">
          <img src={Logo} style={{ width: '150px' }} />
        </Image>
        <IconButtonMb onClick={() => setOpenMb(!openMb)}>
          <Bars />
        </IconButtonMb>
        <NavMenu>
          <NavLink to="/dashboard" onClick={closeAllMenu}>
            {t('navBar.dashboard')}
          </NavLink>
          <NavLink to="/settings/user" onClick={closeAllMenu}>
            {t('navBar.profile')}
          </NavLink>
          <NavLink to="/Wallets" onClick={closeAllMenu}>
            {t('navBar.wallets')}
          </NavLink>
          <NavLink to="/credit-card" onClick={closeAllMenu}>
            {t('navBar.creditcard')}
          </NavLink>
          <NavLink to="/exchange" onClick={closeAllMenu}>
            {t('navBar.tradeview')}
          </NavLink>
        </NavMenu>
        <NavBtn>
          <a
            className="mx-3 text-white"
            onClick={() => {
              if (openLanguage) {
                setOpenLanguage(false);
              } else {
                setOpenLanguage(true);
                setOpen2(false);
                setOpen3(false);
              }
            }}>
            <Globe className="m-2" />
            {t('navBar.language')}
            <Caret />
          </a>
          <NavItem>
            <IconButton
              className="mx-3"
              onClick={() => {
                if (openMb) {
                  setOpenMb(false);
                } else {
                  setOpen2(!open2);
                  setOpen3(false);
                  setOpen(false);
                  setOpenLanguage(false);
                }
              }}>
              <Help />
            </IconButton>
            <a
              className="mx-3 text-white"
              onClick={() => {
                if (openMb) {
                  setOpenMb(false);
                } else {
                  setOpen3(!open3);
                  setOpen(false);
                  setOpen2(false);
                  setOpenLanguage(false);
                }
              }}>
              {props.selectedAccount && props.selectedAccount.AccountName}
              <Caret />
            </a>
            {open ? <DropdownMenu /> : ''}
            {open2 ? <DropdownMenu2 /> : ''}
            {open3 ? <DropdownMenu3 {...props} /> : ''}
            {openLanguage ? <DropdownLang {...props} /> : ''}
          </NavItem>
        </NavBtn>
        {openMb ? (
          <DropdownMenuMb
            closeMenu={() => {
              open && setOpen(false);
              open2 && setOpen2(false);
              open3 && setOpen3(false);
              setOpenMb(false);
            }}
          />
        ) : (
          ''
        )}
      </Nav>
    </React.Fragment>
  );
};

//--------------========= Irrelevant dropdown menu =========--------------
const DropdownMenu = () => {
  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItem>
    );
  };
  return (
    <Dropdown>
      <DropdownItem leftIcon={<FaChartBar />} link="https://app.cryptosx.io">
        Crypto Exchange
      </DropdownItem>{' '}
      <DropdownItem
        leftIcon={<FaLandmark />}
        link="https://investorportal.cryptosx.io">
        STO Exchange
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaLayerGroup />}
        link="https://markets.cryptosx.io/dex">
        DEX
      </DropdownItem>
    </Dropdown>
  );
};

const DropdownMenu2 = () => {
  const [t, i18n] = useTranslation('common');
  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link} target="_blank">
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItem>
    );
  };
  return (
    <Dropdown2>
      <DropdownItem
        leftIcon={<FaUserShield />}
        link="https://help.green-x.io/en/support/login">
        {t('navBar.supportcenter')}
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaSearch />}
        link="https://help.green-x.io/en/support/home">
        {t('navBar.faq')}
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaMoneyBill />}
        link="https://www.green-x.io/fee-schedule.html">
        {t('navBar.fees')}
      </DropdownItem>
    </Dropdown2>
  );
};

const DropdownMenu3 = p => {
  const [t, i18n] = useTranslation('common');
  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link} onClick={props.onClick}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
      </DropdownMenuItem>
    );
  };
  const DropdownItemSpan = props => {
    return (
      <DropdownMenuItemSpan href={props.link} onClick={props.onClick}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
      </DropdownMenuItemSpan>
    );
  };
  // DropdownMenuItemSpan
  return (
    <Dropdown3>
      {// show current account
      p.selectedAccount && (
        <Link to="/settings/user" style={{ width: '100%' }}>
          <DropdownItemSpan leftIcon={<FaUser />}>
            {t('navBar.profile')}
          </DropdownItemSpan>
        </Link>
      )}
      {p.selectableAccounts &&
        p.selectableAccounts.map(a => {
          return (
            <DropdownItem
              onClick={() => {
                p.selectAccount(a);
              }}
              key={a.AccountId}>
              Switch to {a.AccountName}
            </DropdownItem>
          );
        })}
      <DropdownItem
        leftIcon={<FaShareSquare />}
        onClick={async () => {
          p.logout();
        }}>
        {t('navBar.logout')}
      </DropdownItem>
    </Dropdown3>
  );
};

const DropdownLang = p => {
  const [t, i18n] = useTranslation('common');
  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link} onClick={props.onClick}>
        {/* <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon> */}
        {props.children}
      </DropdownMenuItem>
    );
  };

  const updatelanguage = choseLang => {
    localStorage.setItem('language', choseLang);
    window.location.reload();
  };

  return (
    // onClick={() => updatelanguage()}
    // {currentLang == 'en' ? '中文' : 'English'}
    <Dropdown4>
      <DropdownItem onClick={() => updatelanguage('en')}>
        {' '}
        English{' '}
      </DropdownItem>
      <DropdownItem onClick={() => updatelanguage('zh')}> 中文 </DropdownItem>
      <DropdownItem onClick={() => updatelanguage('ko')}> 한국어 </DropdownItem>
    </Dropdown4>
  );
};
//--------------========= Irrelevant dropdown menu =========--------------

const Navbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(_Navbar);

//--------------========= Mobile navbar =========--------------
const _DropdownMenuMb = props => {
  const [t, i18n] = useTranslation('common');
  const updatelanguageZH = () => {
    localStorage.setItem('language', 'zh');
    window.location.reload();
  };

  const updatelanguageEN = () => {
    localStorage.setItem('language', 'en');
    window.location.reload();
  };

  const updatelanguageKO = () => {
    localStorage.setItem('language', 'ko');
    window.location.reload();
  };

  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link} onClick={props.onClick}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItem>
    );
  };

  // when in mobile app, these link can't work when open with window.open
  // use link and href instead
  const DropdownItemExtraLinks = props => {
    return (
      <DropdownMenuItem
        href={props.link}
        onClick={props.onClick}
        target="_blank">
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItem>
    );
  };

  const DropdownItemLink = props => {
    return (
      <DropdownMenuItemLink to={props.to} onClick={() => props.closeMenu()}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItemLink>
    );
  };

  return (
    <DropdownMbOverlay>
      <DropdownMb>
        <DropdownMbClose>
          <FaTimes
            onClick={() => {
              props.closeMenu();
            }}
          />
        </DropdownMbClose>
        {// show current account
        props.selectedAccount && (
          <DropdownItemLink
            leftIcon={<FaUser />}
            to="/settings/verification-level"
            closeMenu={props.closeMenu}>
            <span style={{ fontWeight: 'bold' }}>
              {props.selectedAccount.AccountName}
            </span>
          </DropdownItemLink>
        )}
        {props.selectableAccounts &&
          props.selectableAccounts.map(a => {
            return (
              <DropdownItem
                onClick={() => {
                  props.selectAccount(a);
                  props.closeMenu();
                }}
                key={a.AccountId}>
                Switch to {a.AccountName}
              </DropdownItem>
            );
          })}

        <DropdownItemLink
          leftIcon={<FaUserCog />}
          to="/settings/user"
          closeMenu={props.closeMenu}>
          {t('navBar.profile')}
        </DropdownItemLink>
        <DropdownItemLink
          leftIcon={<FaTable />}
          to="/dashboard"
          closeMenu={props.closeMenu}>
          {t('navBar.dashboard')}
        </DropdownItemLink>
        <DropdownItemLink
          leftIcon={<FaWallet />}
          to="/wallets"
          closeMenu={props.closeMenu}>
          {t('navBar.wallets')}
        </DropdownItemLink>
        {/* <DropdownItemLink
          leftIcon={<FaRandom />}
          to="/buy-sell"
          closeMenu={props.closeMenu}>
          Convert
        </DropdownItemLink> */}
        <DropdownItemLink
          leftIcon={<FaRegCreditCard />}
          to="/credit-card"
          closeMenu={props.closeMenu}>
          {t('navBar.creditcard')}
        </DropdownItemLink>
        <DropdownItemLink
          leftIcon={<FaRegChartBar />}
          to="/exchange"
          closeMenu={props.closeMenu}>
          {t('navBar.tradeview')}
        </DropdownItemLink>
        <div style={{ marginTop: '2rem', height: '1px' }} />
        <DropdownItem
          onClick={() => updatelanguageZH()}
          leftIcon={<FaLanguage />}>
          中文
        </DropdownItem>
        <DropdownItem
          onClick={() => updatelanguageEN()}
          leftIcon={<FaLanguage />}>
          English
        </DropdownItem>
        <DropdownItem
          onClick={() => updatelanguageKO()}
          leftIcon={<FaLanguage />}>
          한국어
        </DropdownItem>

        {/* <DropdownItem leftIcon={<FaChartBar />}>Crypto Exchange</DropdownItem>
        <DropdownItem
          leftIcon={<FaLandmark />}
          link="https://investorportal.cryptosx.io">
          STO Exchange
        </DropdownItem>
        <DropdownItem
          leftIcon={<FaLayerGroup />}
          link="https://markets.cryptosx.io/dex">
          DEX
        </DropdownItem> */}
        <div style={{ marginTop: '3rem', height: '1px' }} />
        <DropdownItemExtraLinks
          link="http://help.green-x.io/en/support/login"
          leftIcon={<FaUserShield />}>
          {t('navBar.supportcenter')}
        </DropdownItemExtraLinks>
        <DropdownItemExtraLinks
          link="https://help.green-x.io/en/support/home"
          leftIcon={<FaSearch />}>
          {t('navBar.faq')}
        </DropdownItemExtraLinks>
        <DropdownItemExtraLinks
          link="https://www.green-x.io/fee-schedule.html"
          leftIcon={<FaMoneyBill />}>
          {t('navBar.fees')}
        </DropdownItemExtraLinks>

        <DropdownItem
          leftIcon={<FaShareSquare />}
          onClick={() => {
            props.logout();
          }}
          closeMenu={props.closeMenu}>
          {t('navBar.logout')}
        </DropdownItem>
      </DropdownMb>
    </DropdownMbOverlay>
  );
};

const DropdownMenuMb = connect(
  mapStateToProps,
  mapDispatchToProps
)(_DropdownMenuMb);

export default Navbar;
// export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
