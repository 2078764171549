import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { FaCaretDown } from 'react-icons/fa';
import { FaQuestionCircle } from 'react-icons/fa';
import { FaTh, FaGlobe } from 'react-icons/fa';

export const Nav = styled.nav`
  background: #12141a;
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding: 25px calc((100vw - 2000px) / 2);
  z-index: 10;
`;

export const Image = styled.a`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

export const NavLink = styled(Link)`
  color: rgba(256, 256, 256, 1);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 1.5rem 0rem;
  height: 100%;
  cursor: pointer;
  font-size: 19px;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  line-height: 23px;

  &.active {
    color: #2aa6a6;
    font-weight: bold;
    transition: all 0.2s ease-in-out;

    svg {
      color: #2aa6a6;
    }
  }

  &:hover {
    color: #2aa6a6;
    transition: all 0.2s ease-in-out;
  }
`;

export const IconButtonMb = styled.a`
  display: none;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: rgba(256, 256, 256, 0.4);
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    position: absolute;
    top: 25px;
    right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: var(--color-secondary);
    transition: filter 300ms;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
`;

export const Bars = styled(FaBars)`
  font-size: 2rem;
  color: #fff;
`;

export const Caret = styled(FaCaretDown)`
  font-size: 1.8rem;
  color: #fafafa;
`;

export const Help = styled(FaQuestionCircle)`
  font-size: 1.8rem;
  color: #fafafa;
`;

export const App = styled(FaTh)`
  font-size: 1.8rem;
  color: #fafafa;
`;

export const Globe = styled(FaGlobe)`
  font-size: 1.8rem;
  color: #fafafa;
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  // width: 200px;
  justify-content: flex-end;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #256ce1;
  padding: 10px 22px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #000;
  }
`;

export const NavItem = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconButton = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 1px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #2aa6a6;
  transition: filter 300ms;
  transition: all 0.3s ease-in-out;
  border: 1px solid #ffffff;

  &:hover {
    transition: all 0.3s ease-in-out;
    // background: #00ccf2;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 80px;
  width: 300px;
  transform: translateX(-60%);
  background-color: #ffffff;
  border: 1px solid #4283fc;
  border-radius: 10px;
  padding: 1rem;
  overflow: hidden;
`;
// support, faq, fees dropdown
export const Dropdown2 = styled.div`
  position: absolute;
  top: 80px;
  width: 300px;
  -webkit-transform: translateX(-65%);
  -ms-transform: translateX(-65%);
  transform: translateX(-65%);
  background-color: #000000;
  border-radius: 10px 10px 0px 0px;
  padding: 1rem;
  -webkit-filter: drop-shadow(rgba(128, 138, 157, 0.12) 0px 1px 2px)
    drop-shadow(rgba(128, 138, 157, 0.24) 0px 8px 32px);
  filter: drop-shadow(rgba(128, 138, 157, 0.12) 0px 1px 2px)
    drop-shadow(rgba(128, 138, 157, 0.24) 0px 8px 32px);
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  border-bottom: 2px solid #2ca5a5;
  &::before {
    content: '';
    display: block;
    width: 0px;
    z-index: 10;
    height: 0px;
    border-width: 10px;
    border-style: solid;
    border-image: initial;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #000000;
    position: absolute;
    top: -20px;
    left: calc(90% - 10px);
  }
`;
// profifle logout dropdown
export const Dropdown3 = styled.div`
  position: absolute;
  top: 80px;
  width: 300px;
  transform: translateX(-20%);
  background-color: #000000;
  // border: 1px solid #5c5c5c;
  border-radius: 10px 10px 0px 0px;
  padding: 1rem;
  -webkit-filter: drop-shadow(rgba(128, 138, 157, 0.12) 0px 1px 2px)
    drop-shadow(rgba(128, 138, 157, 0.24) 0px 8px 32px);
  filter: drop-shadow(rgba(128, 138, 157, 0.12) 0px 1px 2px)
    drop-shadow(rgba(128, 138, 157, 0.24) 0px 8px 32px);
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  border-bottom: 2px solid #2ca5a5;
  &::before {
    content: '';
    display: block;
    width: 0px;
    z-index: 10;
    height: 0px;
    border-width: 10px;
    border-style: solid;
    border-image: initial;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #000000;
    position: absolute;
    top: -20px;
    left: calc(90% - 10px);
  }
`;
// profifle logout dropdown
export const Dropdown4 = styled.div`
  position: absolute;
  top: 80px;
  width: 200px;
  transform: translateX(-20%);
  background-color: #000000;
  // border: 1px solid #5c5c5c;
  border-radius: 10px 10px 0px 0px;
  padding: 1rem;
  -webkit-filter: drop-shadow(rgba(128, 138, 157, 0.12) 0px 1px 2px)
    drop-shadow(rgba(128, 138, 157, 0.24) 0px 8px 32px);
  filter: drop-shadow(rgba(128, 138, 157, 0.12) 0px 1px 2px)
    drop-shadow(rgba(128, 138, 157, 0.24) 0px 8px 32px);
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  border-bottom: 2px solid #2ca5a5;
  right: 200px;
  &::before {
    content: '';
    display: block;
    width: 0px;
    z-index: 10;
    height: 0px;
    border-width: 10px;
    border-style: solid;
    border-image: initial;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #000000;
    position: absolute;
    top: -20px;
    left: calc(90% - 10px);
  }
`;
export const DropdownMbOverlay = styled.div`
  background: #34383bd6;
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
`;
export const DropdownMb = styled.div`
  // position: absolute;
  // top: 80px;
  // right: 5px;
  // width: 90%;
  // height: 400px;
  // background-color: #ffffff;
  // border: 2px solid #4283fc;
  // // border-radius: 20px;
  // padding: 1rem;
  // overflow-y: scroll;

  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  /* height: unset; */
  background-color: var(--color-primary);
  padding: 1rem;
  overflow-y: scroll;
  height: 100%;
  transition: 0.3s ease-in-out;

  @media screen and (min-width: 768px) {
    width: 40%;
  }

  // &::-webkit-scrollbar {
  //   width: 15px!important;
  // }
  // &::-webkit-scrollbar-track {
  //   background: #888888;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: #2aa6a6 ;
  // }
`;

export const DropdownMbClose = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 2.5rem;
  padding: 1rem 0;
`;

export const DropdownMenuItem = styled.a`
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background 0.3s;
  padding: 0.5rem;
  margin: 0.8rem 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: #2aa6a6;
  }

  @media only screen and (max-width: 1024px) {
    height: 50px;
  }
`;

export const DropdownMenuItemSpan = styled.span`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background 0.3s;
  padding: 0.5rem;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: #2aa6a6;
  }

  @media only screen and (max-width: 480px) {
    height: 50px;
  }
`;

export const DropdownMenuItemLink = styled(Link)`
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background 0.3s;
  padding: 0.5rem;
  margin: 0.8rem 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  &.active {
    color: #2aa6a6;
    font-weight: 800;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    background: #898989;
  }
`;

export const DropdownMenuIcon = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #ffffff;
  transition: filter 300ms;
  transition: all 0.3s ease-in-out;
  font-size: 1.8rem;
  // margin-right: auto;
`;

export const DropdownMenuIconRight = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  transition: all 0.3s ease-in-out;
  font-size: 1.8rem;
  margin-left: auto;
`;
