import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { templateFormRenderer } from 'apex-web/lib/helpers/formTemplateHelper';
import {
  required,
  biggerThanZero,
  lessThanValue
} from 'apex-web/lib/helpers/formValidations';
import { showTemplateSelect } from 'apex-web/lib/helpers/withdrawHelper';
import WithdrawWorkflowsContainer from 'apex-web/lib/components/WithdrawSidePaneComponents/WithdrawWorkflows/WithdrawWorkflowsContainer';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import APButton from 'apex-web/lib/components/common/APButton';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APSelect from 'apex-web/lib/components/common/APSelect';
import classnames from 'classnames';

import './FiatWithdrawFormComponent.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var baseClasses = getBEMClasses('fiat-withdraw-form');
var slidePaneClasses = getBEMClasses('fiat-slide-pane');

var FiatWithdrawFormComponent = (function(_React$Component) {
  _inherits(FiatWithdrawFormComponent, _React$Component);

  function FiatWithdrawFormComponent() {
    // check input amount < balance
    // refer to SendFormComponent.js
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, FiatWithdrawFormComponent);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          FiatWithdrawFormComponent.__proto__ ||
          Object.getPrototypeOf(FiatWithdrawFormComponent)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.lessThanBalance = function(value) {
        var balance = _this.props.balance;
        if (balance === 0) return _this.context.t('0 balance available');
        return lessThanValue(balance, value);
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(FiatWithdrawFormComponent, [
    {
      key: 'componentWillMount',
      value: function componentWillMount() {
        var _props = this.props,
          product = _props.product,
          selectWithdrawProduct = _props.selectWithdrawProduct;

        selectWithdrawProduct(product.ProductId);
      }
    },
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.props.initialize(Object.assign({}, this.props.product));
      }
    },
    {
      key: 'render',
      value: function render() {
        var _props2 = this.props,
          handleSubmit = _props2.handleSubmit,
          disableWithdraw = _props2.disableWithdraw,
          withdrawInfo = _props2.withdrawInfo,
          _props2$withdrawInfo = _props2.withdrawInfo,
          withdrawWorkflowInfo = _props2$withdrawInfo.withdrawWorkflowInfo,
          template = _props2$withdrawInfo.template,
          templateTypes = _props2$withdrawInfo.templateTypes,
          isLoading = _props2$withdrawInfo.isLoading,
          error = _props2$withdrawInfo.error,
          product = _props2.product,
          _props2$product = _props2.product,
          ProductSymbol = _props2$product.ProductSymbol,
          ProductId = _props2$product.ProductId,
          balance = _props2.balance,
          fee = _props2.fee,
          total = _props2.total,
          showDetails = _props2.showDetails,
          verificationOnClick = _props2.verificationOnClick,
          selectWithdrawTemplate = _props2.selectWithdrawTemplate;

        var showTemplateTypeSelect = showTemplateSelect(withdrawInfo);
        var useExternalWithdrawWorkflow =
          withdrawWorkflowInfo && Object.values(withdrawWorkflowInfo).length;
        var templateTypeOptions = [
          { value: '', label: 'Select an option' }
        ].concat(
          _toConsumableArray(
            templateTypes.map(function(template) {
              return {
                value: template.TemplateName,
                label: template.TemplateName
              };
            })
          )
        );
        var DecimalPlaces = this.props.products.DecimalPlaces;

        return React.createElement(
          React.Fragment,
          null,
          React.createElement(WithdrawWorkflowsContainer, null),
          React.createElement(
            'form',
            {
              className: classnames(
                slidePaneClasses('body'),
                baseClasses('body')
              ),
              onSubmit: handleSubmit
            },
            React.createElement(
              'div',
              { className: baseClasses('form-body') },
              React.createElement(
                'div',
                { className: baseClasses('frame-container') },
                React.createElement(
                  'div',
                  { className: baseClasses('frame') },
                  disableWithdraw
                    ? React.createElement(VerificationRequiredContainer, {
                        disabled: disableWithdraw,
                        onClick: verificationOnClick
                      })
                    : React.createElement(
                        React.Fragment,
                        null,
                        showTemplateTypeSelect &&
                          React.createElement(APSelect, {
                            name: 'TemplateType',
                            customClass: baseClasses(),
                            label: this.context.t(
                              'Select an option to continue the withdraw process'
                            ),
                            onSelect: function onSelect(value) {
                              return selectWithdrawTemplate(ProductId, value);
                            },
                            options: templateTypeOptions
                          }),
                        !showTemplateTypeSelect &&
                          !template.hasOwnProperty('Amount') &&
                          React.createElement(APNumberInput, {
                            type: 'text',
                            name: 'Amount',
                            labelInInput: ProductSymbol,
                            label: this.context.t(
                              'Amount of ' + ProductSymbol + ' to Withdraw'
                            ),
                            decimalPlaces: DecimalPlaces,
                            customClass: baseClasses(),
                            validate: [required, this.lessThanBalance]
                          }),
                        !useExternalWithdrawWorkflow &&
                          templateFormRenderer(
                            template,
                            baseClasses(''),
                            this.context,
                            product
                          ),
                        !showDetails
                          ? React.createElement(
                              'div',
                              { className: baseClasses('info-items') },
                              React.createElement(APLabelWithText, {
                                label: this.context.t('Current Balance:'),
                                text: balance + ' ' + ProductSymbol,
                                customClass: baseClasses()
                              }),
                              React.createElement(APLabelWithText, {
                                label: this.context.t('Transaction Fee:'),
                                text: fee !== undefined ? fee : '-',
                                customClass: baseClasses()
                              }),
                              React.createElement(APLabelWithText, {
                                label: this.context.t('Final Balance:'),
                                text: total !== undefined ? total : '-',
                                customClass: baseClasses()
                              })
                            )
                          : null,
                        error &&
                          React.createElement(
                            'p',
                            { className: baseClasses('error') },
                            this.context.t(error)
                          ),
                        isLoading &&
                          React.createElement(Spinner, {
                            customClass: baseClasses
                          })
                      )
                )
              ),
              !showTemplateTypeSelect &&
                !disableWithdraw &&
                !useExternalWithdrawWorkflow &&
                React.createElement(
                  'div',
                  { className: baseClasses('footer') },
                  React.createElement(
                    APButton,
                    {
                      customClass: baseClasses(),
                      disabled: disableWithdraw,
                      type: 'submit',
                      styleName: 'additive'
                    },
                    this.context.t('Withdraw'),
                    ' ',
                    ProductSymbol
                  )
                )
            )
          )
        );
      }
    }
  ]);

  return FiatWithdrawFormComponent;
})(React.Component);

FiatWithdrawFormComponent.defaultProps = {
  withdrawInfo: {
    template: {},
    isLoading: false,
    error: '',
    withdrawWorkflowInfo: [],
    withdrawStatus: {}
  }
};

FiatWithdrawFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  showSnack: PropTypes.func,
  withdrawInfo: PropTypes.shape({
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    product: PropTypes.number,
    withdrawWorkflowInfo: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]).isRequired,
    withdrawStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  }),
  disableWithdraw: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

FiatWithdrawFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatWithdrawFormComponent;
