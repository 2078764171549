import React from 'react';
import StandAloneLayout from '../../../layouts/StandAloneLayout/StandAloneLayout';
import LoginFormContainer from './LoginFormContainer';

export default (function() {
  return React.createElement(
    StandAloneLayout,
    null,
    React.createElement(LoginFormContainer, null)
  );
});
 