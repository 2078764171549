import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';
import LoginFormComponent from './LoginFormComponent';
import {
  login,
  AUTH_2FA_REQUIRED,
  ENABLE_2FA_REQUIRED
} from 'apex-web/lib/redux/actions/authActions';
import path from '../../helpers/path';
import { withRouter } from 'react-router';
import config from '../../config';
import { getLoginFormGateway } from '../../helpers/wsHelper';
import { initApex, setGateway } from '../../apex';
import {
  connectionClosed,
  connectionClosedCount
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { loadStartupData } from 'apex-web/lib/redux/actions/appActions';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';
import React from 'react';
/*import {
  login,
} from '../../redux/action/authAction';*/

var globalSiteName = config.global.siteName,
  LoginForm = config.LoginForm,
  _config$SignupForm = config.SignupForm,
  useEmailAsUsername = _config$SignupForm.useEmailAsUsername,
  active = _config$SignupForm.active;

var gateway = getLoginFormGateway();

var siteName =
  LoginForm && LoginForm.siteName ? LoginForm.siteName : globalSiteName;

var mapStateToProps = function mapStateToProps(state) {
  return {
    auth: state.auth,
    errorMsg: state.auth.errorMsg,
    isConnected: state.wsConnection.isConnected,
    useEmailAsUsername: useEmailAsUsername,
    active: active,
    siteName: siteName,
    gateway: gateway,
    formGateway: formValueSelector('login')(state, 'gateway')
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    resetForm: function resetForm() {
      return dispatch(change('login', 'gateway', ''));
    },
    doSubmit: function doSubmit(payload, isConnected) {
      if (!isConnected || !navigator.onLine) {
        setGateway(payload.gateway);
        localStorage.setItem('tradingServer', payload.gateway);
        initApex(
          function() {
            //alert(JSON.stringify(payload, null, 2));
            dispatch(login(payload)).then(function(action) {
              dispatch(connectionClosedCount(0));
              dispatch(loadStartupData());
              dispatch(onInit());

              if (action.type === ENABLE_2FA_REQUIRED) {
                ownProps.history.push(path('/enabletwofactorauth'));
              } else if (action.type === AUTH_2FA_REQUIRED) {
                ownProps.history.push(path('/twofactorauth'));
              }
            });
          },
          function() {
            return dispatch(connectionClosed());
          }
        );
        return;
      }
      //alert(JSON.stringify(payload, null, 2));

      return dispatch(login(payload)).then(function(action) {
        if (action.type === ENABLE_2FA_REQUIRED) {
          ownProps.history.push(path('/enabletwofactorauth'));
        } else if (action.type === AUTH_2FA_REQUIRED) {
          ownProps.history.push(path('/twofactorauth'));
        }
      });
    },
    login: function (payload) {
      return dispatch(login(payload)).then(function(action) {
        let {payload: {isAuthenticated}} = action;
        if (action.type === ENABLE_2FA_REQUIRED) {
          ownProps.history.push(path('/enabletwofactorauth'));
        } else if (action.type === AUTH_2FA_REQUIRED) {
          ownProps.history.push(path('/twofactorauth'));
        }
        if (isAuthenticated)
          window.location.reload();
      });
    },
  };
};

function getQueryParams() {
  let matchArray = window.location.href.match(/\?(.*)/);
  if (matchArray) {
    return (
      matchArray[1].split('&').reduce((acc, p) => {
        let ps = p.split('=');
        if (ps.length < 2)
          return acc;
        return Object.assign(acc, {[decodeURIComponent(ps[0])]: decodeURIComponent(ps.slice(1).join('='))});
      }, {})
    );
  }
  return {};
};

let LoginFormContainer = (LoginFormComponent => (
  function (props, ...args) {
    let {login} = props;
    React.useEffect(() => {
      let {sessionToken} = getQueryParams();
      if (typeof sessionToken === 'string') {
        login({sessionToken}, true);
      }
    }, []);
    return React.createElement(LoginFormComponent, props, ...args);
  }
))(LoginFormComponent);
LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormContainer);
/*var LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent);*/

var LoginFormContainerForm = reduxForm({
  form: 'login',
  onSubmit: function onSubmit() {}
})(LoginFormContainer);

export default withRouter(LoginFormContainerForm);
