var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import APIcon from 'apex-web/lib/components/common/APIcon';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import destinationTagHelper from 'apex-web/lib/helpers/destinationTagHelper';
import klaytn from './klaytn-api'
import './ReceiveAddress.css';
import BigNumber from 'bignumber.js';

var bemClasses = getBEMClasses('receive-address');

let getDecimalPlaces = v => {
  let n = 0;
  let ten = new BigNumber(10);
  while (!(v.isInteger())) {
    v = v.times(ten);
    n++;
  }
  return n;
};
BigNumber.prototype.toProperFixed = function () {
  return this.toFixed(getDecimalPlaces(this));
}

function ReceiveAddressComponent(props, context) {
  let [balance, set_balance] = React.useState(new BigNumber(-1));
  let [gasPrice, set_gasPrice] = React.useState(new BigNumber(0));
  let cost = React.useMemo(() => gasPrice.times(4e5), [gasPrice]);
  let [accountAddress, set_accountAddress] = React.useState('0x0000000000000000000000000000000000000000');
  let remainingCost = cost.minus(balance);

  React.useEffect(() => {
    var selectDepositProduct = props.selectDepositProduct,
      product = props.product;

    var Account_provider_id = props.account_provider_ID;

    selectDepositProduct(product.ProductId, Account_provider_id);
  }, []);

  React.useEffect(() => {
    let shouldStop = false;
    let { account } = props;
    let addressList = props.deposit.depositInfo;
    if (account && addressList.length != 0) {
      klaytn.getDepositAddress_(account.AccountId)
        .then(_ => {
          if (typeof _.address === 'string') {
            return _;
          }
          throw new Error;
        })
        .then(({address: depositAddress, isReady}) => {
          if (isReady) {
            if (addressList.every(address => address.toLowerCase() !== depositAddress.toLowerCase()))
              props.updateDepositInfo(product.ProductId);
          } else {
            klaytn.detectAccountAddress(account.AccountId).catch(console.error);
            klaytn.getAccountState(account.AccountId)
              .then(({accountAddress, gasPrice, balance}) => {
                if (!shouldStop) {
                  if (
                    typeof accountAddress === 'string' && 
                    typeof gasPrice === 'string' &&
                    typeof balance === 'string'
                  ) {
                    set_accountAddress(accountAddress);
                    set_gasPrice(new BigNumber(gasPrice));
                    set_balance(new BigNumber(balance));
                  }
                }
              })
              .catch(console.error);
          }
        })
        .catch(console.error);
    }
    return () => {shouldStop = true};
  }, [props.account, props.deposit.depositInfo]);

  var addressList = props.deposit.depositInfo,
    _props2$deposit = props.deposit,
    isLoading = _props2$deposit.isLoading,
    error = _props2$deposit.error,
    product = props.product,
    showSnack = props.showSnack,
    WalletsDisabledMessage = props.WalletsDisabledMessage;

  // Protocol prefix for the QR Code, lowercase name with no spaces.

  var depositType = product.ProductFullName.toLowerCase().replace(/\W/g, '');
  var selectedAddress = void 0;
  var needDeployment = false;

  if (!isLoading && addressList.length) {
    selectedAddress = addressList[addressList.length - 1];
    if (addressList.length != 1)
      selectedAddress = addressList.find(address => address !== '0x0000000000000000000000000000000000000000') || selectedAddress;
    if (selectedAddress === '0x0000000000000000000000000000000000000000')
      needDeployment = true;
  }
  
  var _destinationTagHelper = destinationTagHelper(selectedAddress),
    _destinationTagHelper2 = _slicedToArray(_destinationTagHelper, 2),
    address = _destinationTagHelper2[0],
    destinationTag = _destinationTagHelper2[1];
  if (needDeployment) {
    address = accountAddress;
  }

  // This is hard-coded for Product which have both ERC-20 and BEP-20 enabled at the same time.
  // Display a option for user to choose between ERC and BEP.
  // You need to add the Product ID and account provider ID mannuly in the code, when there is a new product need this function.
  // Requested by Philip.
  return (
    React.createElement(
      'section',
      { className: bemClasses() },
      selectedAddress && !error && React.createElement(
        React.Fragment,
        null,
        React.createElement(
          'p',
          { className: bemClasses('description'), style: { fontSize: "2rem" } },
          needDeployment ? (
            balance.isNegative() ?
            'Loading...' :
            remainingCost.isNegative() ?
            context.t('Your deposit address is being created on-chain') :
            `Deposit ${remainingCost.div(new BigNumber(10).pow(18)).toProperFixed()} KLAY into the KLAY account address below to create your deposit address on-chain` 
          ) : 
          context.t('Scan this QR code to receive funds:')
        ),
        needDeployment && !(balance.isNegative()) && React.createElement(
          'span',
          { className: bemClasses('address') },
          context.t('KLAY Account Balance') + ': ' + balance.div(new BigNumber(10).pow(18)).toProperFixed() + ' ' + context.t('KLAY')
        ),
        needDeployment && React.createElement(
          'span',
          { className: bemClasses('address') },
          context.t('Deposit Address Deployment Cost') + ': ' + cost.div(new BigNumber(10).pow(18)).toProperFixed() + ' ' + context.t('KLAY')
        ),
        needDeployment && React.createElement(
          'span',
          { className: bemClasses('address') },
          context.t('Deposit Address Deployment Gas Price') + ': ' + gasPrice.div(new BigNumber(10).pow(18)).toProperFixed() + ' ' + context.t('KLAY')
        ),
        needDeployment && React.createElement('br'),
        React.createElement(
          'div',
          { className: bemClasses('qr-code') },
          React.createElement(APQRCode, { value: '' + selectedAddress })
        ),
        React.createElement(
          'p',
          { className: bemClasses('copy-address-text') },
          context.t('Or copy this address:')
        ),
        React.createElement(
          'div',
          {
            className: bemClasses('copy-address-payload'),
            onClick: function onClick() {
              showSnack({
                id: 'RECEIVE_ADDRESS_COPY',
                text: context.t('The address has been copied to the clipboard.')
              });
              copyToClipboard(address);
            }
          },
          React.createElement(
            'span',
            { className: bemClasses('address') },
            address
          ),
          React.createElement(APIcon, { name: 'copy', customClass: bemClasses('copy-icon') })
        ),
        destinationTag && React.createElement(
          'div',
          {
            onClick: function onClick() {
              showSnack({
                id: 'RECEIVE_ADDRESS_COPY',
                text: context.t('The destination tag has been copied to the clipboard.')
              });
              copyToClipboard(destinationTag);
            }
          },
          React.createElement(
            'p',
            { className: bemClasses('destination-tag-text') },
            context.t('Be sure to include this destination tag:')
          ),
          React.createElement(
            'span',
            { className: bemClasses('address') },
            destinationTag
          ),
          React.createElement(APIcon, { name: 'copy', customClass: bemClasses('copy-icon') })
        )
      ),
      isLoading && React.createElement(Spinner, {
        text: context.t('Loading url...'),
        customClass: bemClasses
      }),
      WalletsDisabledMessage ? React.createElement(
        'span',
        { className: bemClasses('error') },
        context.t(WalletsDisabledMessage)
      )
        :
        // Tmp sol. , don't show error , since this may not a real error. (means that a account provider return null is still a error , but it is not)
        // error && React.createElement(
        //   'span',
        //   { className: bemClasses('error') },
        //   context.t(error)
        // )
        ""
    )
  )
}

/*var ReceiveAddressComponent = function (_React$Component) {


  _inherits(ReceiveAddressComponent, _React$Component);

  function ReceiveAddressComponent() {
    _classCallCheck(this, ReceiveAddressComponent);

    return _possibleConstructorReturn(this, (ReceiveAddressComponent.__proto__ || Object.getPrototypeOf(ReceiveAddressComponent)).apply(this, arguments));
  }

  _createClass(ReceiveAddressComponent, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _props = this.props,
        selectDepositProduct = _props.selectDepositProduct,
        product = _props.product;

      var Account_provider_id = this.props.account_provider_ID;

      selectDepositProduct(product.ProductId, Account_provider_id);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      // console.log(this.props)

      var _props2 = this.props,
        addressList = _props2.deposit.depositInfo,
        _props2$deposit = _props2.deposit,
        isLoading = _props2$deposit.isLoading,
        error = _props2$deposit.error,
        product = _props2.product,
        showSnack = _props2.showSnack,
        WalletsDisabledMessage = _props2.WalletsDisabledMessage;

      // Protocol prefix for the QR Code, lowercase name with no spaces.
      
      var depositType = product.ProductFullName.toLowerCase().replace(/\W/g, '');
      var selectedAddress = void 0;
      var needDeployment = false;

      if (!isLoading && addressList.length) {
        selectedAddress = addressList[addressList.length - 1];
        if (addressList.length != 1)
          selectedAddress = addressList.find(address => address !== '0x0000000000000000000000000000000000000000') || selectedAddress;
        if (selectedAddress === '0x0000000000000000000000000000000000000000')
          needDeployment = true;
      }

      var _destinationTagHelper = destinationTagHelper(selectedAddress),
        _destinationTagHelper2 = _slicedToArray(_destinationTagHelper, 2),
        address = _destinationTagHelper2[0],
        destinationTag = _destinationTagHelper2[1];

        //klaytn.getAccountState(6228).then(_ => JSON.stringify(_, null, 2)).then(alert)
      
      // This is hard-coded for Product which have both ERC-20 and BEP-20 enabled at the same time.
      // Display a option for user to choose between ERC and BEP.
      // You need to add the Product ID and account provider ID mannuly in the code, when there is a new product need this function.
      // Requested by Philip.
      return (
        React.createElement(
          'section',
          { className: bemClasses() },
          selectedAddress && !error && React.createElement(
            React.Fragment,
            null,
            React.createElement(
              'p',
              { className: bemClasses('description'), style: { fontSize: "2rem" } },
              needDeployment ? this.context.t('Deploy your deposit address contract') : this.context.t('Scan this QR code to receive funds:')
            ),
            React.createElement(
              'div',
              { className: bemClasses('qr-code') },
              React.createElement(APQRCode, { value: '' + selectedAddress })
            ),
            React.createElement(
              'p',
              { className: bemClasses('copy-address-text') },
              this.context.t('Or copy this address:')
            ),
            React.createElement(
              'div',
              {
                className: bemClasses('copy-address-payload'),
                onClick: function onClick() {
                  showSnack({
                    id: 'RECEIVE_ADDRESS_COPY',
                    text: _this2.context.t('The address has been copied to the clipboard.')
                  });
                  copyToClipboard(address);
                }
              },
              React.createElement(
                'span',
                { className: bemClasses('address') },
                address
              ),
              React.createElement(APIcon, { name: 'copy', customClass: bemClasses('copy-icon') })
            ),
            destinationTag && React.createElement(
              'div',
              {
                onClick: function onClick() {
                  showSnack({
                    id: 'RECEIVE_ADDRESS_COPY',
                    text: _this2.context.t('The destination tag has been copied to the clipboard.')
                  });
                  copyToClipboard(destinationTag);
                }
              },
              React.createElement(
                'p',
                { className: bemClasses('destination-tag-text') },
                this.context.t('Be sure to include this destination tag:')
              ),
              React.createElement(
                'span',
                { className: bemClasses('address') },
                destinationTag
              ),
              React.createElement(APIcon, { name: 'copy', customClass: bemClasses('copy-icon') })
            )
          ),
          isLoading && React.createElement(Spinner, {
            text: this.context.t('Loading url...'),
            customClass: bemClasses
          }),
          WalletsDisabledMessage ? React.createElement(
            'span',
            { className: bemClasses('error') },
            this.context.t(WalletsDisabledMessage)
          )
            :
            // Tmp sol. , don't show error , since this may not a real error. (means that a account provider return null is still a error , but it is not)
            // error && React.createElement(
            //   'span',
            //   { className: bemClasses('error') },
            //   this.context.t(error)
            // )
            ""
        )
      )


    }
  }]);

  return ReceiveAddressComponent;
}(React.Component);*/

ReceiveAddressComponent.defaultProps = {
  deposit: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    depositInfo: {},
    depositStatus: {}
  },
  product: {},
  account_provider_id: null
};

ReceiveAddressComponent.propTypes = {
  product: PropTypes.object,
  account_provider_id: PropTypes.number,
  deposit: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    product: PropTypes.number,
    depositInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    depositStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  })
};

ReceiveAddressComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveAddressComponent;