import { connect } from 'react-redux';
import TVChartComponent from './TVChartComponent';
import { changeTickersInterval } from 'apex-web/lib/redux/actions/tickersActions';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import config from 'apex-web/lib/config/index';

var mapStateToProps = function mapStateToProps(state) {
  var selectedInstrument = selectedInstrumentSelector(state);
  var decimalPlaces = state.apexCore.product.decimalPlaces;
  var theme = config.global.theme;

  var localConfig = JSON.parse(localStorage.getItem('apexTemplate')) || {};

  return {
    selectedInstrument: selectedInstrument,
    decimalPlaces: decimalPlaces,
    theme: localConfig.theme || theme
  };
};

var mapDispatchToProps = {
  changeTickersInterval: changeTickersInterval
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TVChartComponent);
