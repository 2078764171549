import React, { useEffect, useState } from 'react';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { connect } from 'react-redux';
import 'apex-web/lib/layouts/Responsive/EotcBuySellLayout/EotcBuySellDesktopLayout.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import PrepaidCardsComponent from './PrepaidCardsComponent';
import CardImg from '../images/custom/cards.png';
// import './PrepaidCardsPage.css';
import { getWithdrawFee as _getWithdrawFee } from 'apex-web/lib/redux/actions/withdrawActions';
import { createWithdrawGiftCardTicket } from '../redux/action/withdrawGiftCardActions';
import { redeemGiftCardByWithdrawTicket as _redeemGiftCardByWithdrawTicket } from '../redux/action/fetchPhazaApi';
import apex from 'apex-web/lib/apex';
import { callAPI as _callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { productsListSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import { reduxForm, formValueSelector } from 'redux-form';
import { getAvailableBalance } from 'apex-web/lib/helpers/withdrawHelper';

const classes = getBEMClasses('eotc-buy-sell-layout');

let mapStateToProps = function mapStateToProps(state) {
  // Get product Info
  let _products = productsListSelector(state);

  // Get Account Balance of Product
  let _position = positionSelector(state);

  let USDPositionInfo;
  let USDProductInfo;
  if (_products !== undefined) {
    USDProductInfo = _products.find(e => e.Product === 'USD');
  }
  if (USDProductInfo !== undefined && _position != undefined) {
    USDPositionInfo = _position[USDProductInfo.ProductId];
  }
  let USDAvaliableBalance = 0;
  if (USDPositionInfo !== undefined) {
    USDAvaliableBalance = getAvailableBalance(
      USDPositionInfo.Amount,
      USDPositionInfo.Hold
    );
  }
  return {
    products: _products,
    USDProductInfo,
    USDPositionInfo,
    USDAvaliableBalance,
    position: _position
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    // getWithdrawFee: function getWithdrawFee(productId, amount) {
    //   console.log("getWithdrawFee",productId,amount )
    //   return dispatch(_getWithdrawFee(productId, amount));
    // },
    submitWithdraw: function submitWithdraw(payload) {
      console.log('submitWithdraw', payload);
      return dispatch(createWithdrawGiftCardTicket(payload));
    },
    // redeemGiftCardByWithdrawTicket,
    redeemGiftCardByWithdrawTicket: function redeemGiftCardByWithdrawTicket(
      giftCard
    ) {
      return dispatch(_redeemGiftCardByWithdrawTicket(giftCard));
    }
  };
};

// apex.connection.RPCPromise(endpoint, apiPayload);
const PrepaidCardsPage = props => {
  const [giftCardList, setGiftCardList] = useState([]);

  const getWithdrawFeeOfDifferentCard = async positionInfo => {
    const giftCards = [
      {
        price: 10,
        productId: 10113231145,
        type: 'VISA',
        priceWithFee: -1,
        currency: 'USD'
      },
      {
        price: 100,
        productId: 10113231145,
        type: 'VISA',
        priceWithFee: -1,
        currency: 'USD'
      },
      {
        price: 200,
        productId: 10113231145,
        type: 'VISA',
        priceWithFee: -1,
        currency: 'USD'
      },
      {
        price: 500,
        productId: 10113231145,
        type: 'VISA',
        priceWithFee: -1,
        currency: 'USD'
      },
      {
        price: 1000,
        productId: 10113231145,
        type: 'VISA',
        priceWithFee: -1,
        currency: 'USD'
      },
      {
        price: 100,
        productId: 10113251054,
        type: 'MASTER',
        priceWithFee: -1,
        currency: 'USD'
      },
      {
        price: 200,
        productId: 10113251054,
        type: 'MASTER',
        priceWithFee: -1,
        currency: 'USD'
      },
      {
        price: 500,
        productId: 10113251054,
        type: 'MASTER',
        priceWithFee: -1,
        currency: 'USD'
      },
      {
        price: 1000,
        productId: 10113251054,
        type: 'MASTER',
        priceWithFee: -1,
        currency: 'USD'
      }
      // 10113251054
    ];

    // find withdraw fee of each unique price amount
    const withdrawAmount = [];
    let withdrawFeeList = [];
    giftCards.map(g => {
      if (!withdrawAmount.includes(g.price)) {
        withdrawAmount.push(g.price);
      }
    });
    for (let i = 0; i < withdrawAmount.length; i++) {
      const withdrawFeeRes = await apex.connection.RPCPromise(
        'GetWithdrawFee',
        {
          OMSId: positionInfo.OMSId,
          ProductId: positionInfo.ProductId,
          AccountId: positionInfo.AccountId,
          Amount: withdrawAmount[i]
        }
      );
      try {
        let res = JSON.parse(withdrawFeeRes.o);
        // console.log("fee res", res)
        let fee = Number(res.FeeAmount.toFixed(2));
        // console.log("fee", fee)
        withdrawFeeList.push({
          amount: withdrawAmount[i],
          fee
        });
      } catch (error) {
        withdrawFeeList.push({
          amount: withdrawAmount[i],
          fee: -1
        });
      }
    }

    // set the priceWithFee
    // console.log("withdrawFeeList", withdrawFeeList)
    giftCards.map(g => {
      let amountFeeSet = withdrawFeeList.find(e => e.amount === g.price);
      // console.log("amountFeeSet", amountFeeSet)
      if (amountFeeSet.fee !== undefined && amountFeeSet.fee >= 0) {
        g.priceWithFee = g.price + amountFeeSet.fee;
      }
    });

    // console.log("giftCards", giftCards)
    setGiftCardList(giftCards);
  };

  const submitWithdrawGiftCard = (currencyProductId, giftCard) => {
    const userConfirm = window.confirm(
      `This ${giftCard.price} ${giftCard.currency} ${
        giftCard.type
      } Card will cost you ${
        giftCard.priceWithFee
      } including withdraw Fee, confirm ?`
    );
    if (userConfirm) {
      props.submitWithdraw({
        ProductId: currencyProductId,
        Amount: giftCard.price,
        isGiftCard: true,
        giftCardCurrency: giftCard.currency,
        giftCardType: giftCard.type,
        giftCardProductID: giftCard.productId,
        giftCardAmount: giftCard.price
      });
    }
  };

  useEffect(
    () => {
      console.log('props', props);

      // // testRPC()
    },
    [props]
  );

  useEffect(
    () => {
      if (props.USDPositionInfo !== undefined) {
        getWithdrawFeeOfDifferentCard(props.USDPositionInfo);
      }
    },
    [props.USDPositionInfo]
  );

  useEffect(
    () => {
      console.log('withdrawInfo', props.withdrawInfo);
    },
    [props.withdrawInfo]
  );

  // block if balance not loaded
  if (
    props.USDProductInfo === undefined ||
    props.USDPositionInfo === undefined
  ) {
    return <React.Fragment>Loading Balance...</React.Fragment>;
  }
  // selectedProductId
  return (
    <React.Fragment>
      <div className={classes('accent-bar')} />
      <div className={classes('container')}>
        <div className={classes('col', ['lg'])}>
          <div className="title-container">
            <div className="col-left">
              <h2>Cash out via Prepaid Credit Cards</h2>
            </div>
            <div className="col-right">
              <img className="card-img" src={CardImg} />
            </div>
          </div>
          <div
            className={classes(
              'make-transaction-container container-with-shadow grid-container'
            )}>
            <div className="container-cards">
              <div className="row">
                <div className="col-12">
                  <div className="border-bottom">
                    <div className="row">
                      <div className="col-lg-9 col-md-8">
                        <div className="section-title">
                          <h3 className="title mb-2">Select your offer</h3>
                          <h4 className="text-muted mb-2">
                            Balance will be deducted from your USD Cash Account
                            once you have made the purchase
                          </h4>
                          <h2 className="text-muted mb-2">
                            Avaliable USD balance {props.USDAvaliableBalance}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-4 pt-2">
                  <div className="section-title">
                    <h5 className="mb-0">Avaliable Options:</h5>
                  </div>
                </div>
                {giftCardList.map((g, gIndex) => {
                  let avaliableCard = false;
                  if (
                    g.priceWithFee !== -1 &&
                    g.priceWithFee <= props.USDAvaliableBalance
                  ) {
                    avaliableCard = true;
                  }
                  return (
                    <PrepaidCardsComponent
                      key={gIndex}
                      price={g.price}
                      type={g.type}
                      productId={g.productId}
                      avaliable={avaliableCard}
                      submitWithdraw={() =>
                        submitWithdrawGiftCard(
                          props.USDProductInfo.ProductId,
                          g
                        )
                      }
                      // submitWithdraw={async()=> await props.redeemGiftCardByWithdrawTicket(g)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

// export default withAuthentication(PrepaidCardsPage);
// var PrepaidCardsPageContainer = connect(mapStateToProps, {redeemGiftCardByWithdrawTicket, ...mapDispatchToProps})(PrepaidCardsPage);
var PrepaidCardsPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrepaidCardsPage);
export default reduxForm({
  form: 'createWithdraw',
  onSubmit: function onSubmit() {}
})(withAuthentication(PrepaidCardsPageContainer));
