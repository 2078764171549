import React from 'react';
import { connect } from 'react-redux';
import {
  logout,
  redirectFromLogin,
  authError,
} from 'apex-web/lib/redux/actions/authActions';
import apex from 'apex-web/lib/apex'

function redirectIfAuthorized(WrappedComponent) {
  var ModifiedComponent = function ModifiedComponent(props) {
    var userId = props.userId,
        sessionToken = props.sessionToken,
        redirect = props.redirect,
        logout = props.logout,
        showAuthErrorMessage = props.showAuthErrorMessage;

    React.useEffect(() => {
      let externalId = localStorage.getItem('externalId');
      if (typeof userId === 'number' && typeof sessionToken === 'string' && typeof externalId === 'string') {
        ((userId, sessionToken, externalId) => {
          apex.connection.GetUserInfo({UserId: userId})
            .then(user => {
              let {UserName} = user;
              if (typeof UserName === 'string') {
                return (
                  fetch(`https://card.cryptosx.io/greenx-sync/api/timeex/sign-in`, {
                    method: 'POST',
                    headers: {
                      'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                      externalId,
                      sessionToken,
                    })
                  })
                    .then(_ => _.json())
                    .then(({error, payload}) => {
                      if (!error) {
                        return payload;
                      }
                      if (typeof payload === 'string')
                        throw new Error(payload);
                      throw new Error(`This 2U Chat user doesn't exist.`);
                    })
                    .then(({redirectUrl}) => {
                      if (typeof redirectUrl === 'string') {
                        redirect(redirectUrl);
                      } else {
                        throw new Error('The redirection fails.');
                      }
                    })
                );
              }
              throw new Error('The user cannot be found.');
            })
            .catch(reason => {
              showAuthErrorMessage(`${reason}`);
              setTimeout(() => logout(), 2e3);
            });
        })(userId, sessionToken, externalId);
      }
      return () => {};
    }, [userId, sessionToken, redirect, showAuthErrorMessage]);

    return React.createElement(WrappedComponent, props);
  };

  var mapStateToProps = function mapStateToProps(state) {
    return {
      //isAuthenticated: state.auth.isAuthenticated,
      //shouldRedirect: state.auth.shouldRedirect,
      userId: state.auth.userId,
      sessionToken: state.auth.token,
    };
  };
  function mapDispatchToProps(dispatch) {
    return {
      logout: () => dispatch(logout()),
      redirect: url => {
        window.location.href = url;
        dispatch(redirectFromLogin(url))
      },
      showAuthErrorMessage: (msg) => dispatch(authError({Authenticated: false, errormsg: msg}))
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(ModifiedComponent);
}

export default redirectIfAuthorized;