import { connect } from 'react-redux';
import WithdrawWorkflowsComponent from './WithdrawWorkflowsComponent';
import config from 'apex-web/lib/config';

var mapStateToProps = function mapStateToProps(state) {
  return {
    templateType: config.Withdraw.Template || state.withdraw.templateType,
    loading: state.withdraw.isLoading
  };
};

export default connect(mapStateToProps)(WithdrawWorkflowsComponent);