import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TransferHistoryContainer from 'apex-web/lib/components/Settings/TransferHistory';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './UserContactsPage.css';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';
import { connect } from 'react-redux';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import APTable from 'apex-web/lib/components/common/APTable';

const rwaOrderHistoryPageClasses = getBEMClasses('user-contacts-page');

const RwaOrderHistoryPage = (props, context) => {
  var baseClasses = getBEMClasses('api-key-list', props.customClass);
  React.useEffect(() => {
    props.getUserInfo();
    props.getUserConfig();
  }, []);

  let mockData = [
    {
      name: 'GX Token',
      date: '12-06-2024',
      orderQty: '5000',
      amountPaid: '1000 USDT',
      status: 'Pending'
    },
    {
      name: 'GX Token',
      date: '12-06-2024',
      orderQty: '5000',
      amountPaid: '1000 USDT',
      status: 'Pending'
    }
  ];

  var columns = [
    {
      header: context.t('RWA Name'),
      cell: function cell(row) {
        return row.name;
      }
    },
    {
      header: context.t('Date'),
      cell: function cell(row) {
        return row.date;
      }
    },
    {
      header: context.t('Order Qty'),
      cell: function cell(row) {
        return row.orderQty;
      }
    },
    {
      header: context.t('Amount Paid'),
      cell: function cell(row) {
        return row.amountPaid;
      }
    },
    {
      header: context.t('Status'),
      cell: function cell(row) {
        return row.status;
      }
    }
  ];

  var table = () => {
    return React.createElement(
      'div',
      { className: baseClasses() },
      React.createElement(APTable, {
        componentName: 'RWAOrderHistory',
        columns: columns,
        // rows: props.user.userConfig,
        rows: mockData,
        baseClass: baseClasses,
        headerOutside: true,
        empty: context.t('No data is available')
      })
    );
  };

  return (
    <div className={rwaOrderHistoryPageClasses()}>
      <div className={rwaOrderHistoryPageClasses('block-with-text')}>
        <div className={rwaOrderHistoryPageClasses('header-text')}>
          {context.t('RWA Order History')}
        </div>
        <div className={rwaOrderHistoryPageClasses('text')}>
          {context.t('Check your order history')}
        </div>
      </div>
      <div className={rwaOrderHistoryPageClasses('transfer')}>
        <div className={rwaOrderHistoryPageClasses('transfer-header')}>
          {context.t('Order History')}
        </div>
        {table()}
      </div>
    </div>
  );
};
RwaOrderHistoryPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user
  };
};

var mapDispatchToProps = {
  getUserInfo,
  getUserConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(RwaOrderHistoryPage));
