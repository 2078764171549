import React from 'react';
import PropTypes from 'prop-types';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import './BulkCancelButtonsComponent.css';

var baseClasses = getBEMClasses('bulk-cancel-buttons');

var BulkCancelButtonsComponent = function BulkCancelButtonsComponent(props, context) {
  var openOrders = props.openOrders,
      buyOpenOrders = props.buyOpenOrders,
      sellOpenOrders = props.sellOpenOrders,
      cancelAllOrders = props.cancelAllOrders,
      cancelBuyOrders = props.cancelBuyOrders,
      cancelSellOrders = props.cancelSellOrders;

  return React.createElement(
    'div',
    { className: baseClasses() },
    React.createElement(
      'span',
      { className: baseClasses('label') },
      context.t('Cancel:')
    ),
    React.createElement(CancelAPButton, {
      customClass: baseClasses(),
      text: context.t('Sells'),
      disabled: sellOpenOrders.length === 0,
      onClick: cancelSellOrders
    }),
    React.createElement(CancelAPButton, {
      customClass: baseClasses(),
      text: context.t('Buys'),
      disabled: buyOpenOrders.length === 0,
      onClick: cancelBuyOrders
    }),
    React.createElement(CancelAPButton, {
      customClass: baseClasses(),
      text: context.t('All'),
      disabled: openOrders.length === 0,
      onClick: cancelAllOrders
    })
  );
};

BulkCancelButtonsComponent.propTypes = {
  cancelAllOrders: PropTypes.func.isRequired,
  cancelBuyOrders: PropTypes.func.isRequired,
  cancelSellOrders: PropTypes.func.isRequired
};

BulkCancelButtonsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BulkCancelButtonsComponent;