import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formattedTransfersRequestSelector } from 'apex-web/lib/redux/selectors/transfersTableSelectors';
import PropTypes from 'prop-types';
import PageSubHeaderLayout from '../layouts/PageSubHeaderLayout/PageSubHeaderLayout';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
// import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import BalancesListContainer from 'apex-web/lib/components/BalancesList';
import MarketOverviewComponent from 'apex-web/lib/components/MarketOverview';
import RecentActivityContainer from 'apex-web/lib/components/RecentActivity/RecentActivityContainer';
import TransferRequestNotificationList from 'apex-web/lib/components/TransferRequestsNotification/';
import PriceOverview from '../components/PriceOverview/PriceOverview';
import logo_gx from '../pages/img/gx-token.png';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './DashboardPage.css';
import { Link } from 'react-router-dom';

const layoutClasses = getBEMClasses('dashboard-page');
const DashboardPage = (props, context) => {
  const [showForm, setShowForm] = useState(true);
  const sessionEndTime = 'June 14, 2024 00:00:00';
  const targetDate = new Date(sessionEndTime);

  useEffect(() => {
    document.title = 'Green-X Exchange';
    let language = window.location.href.split('/').pop();
    console.log(window.APEXTranslations, 'language');
    if (language === 'zh') {
    } else if (language === 'en') {
    }

    // Countdown then hide form
    const currentDate = new Date();
    if (currentDate > targetDate) {
      setShowForm(false);
    }
  }, []);
  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />
      {/*<PageSubHeaderLayout />*/}
      <div className={layoutClasses()}>
        <div className={layoutClasses('notification-row')}>
          {/* if no transfer request , hide the banner */}
          {props.filteredItems.length >= 1 ? (
            <TransferRequestNotificationList />
          ) : (
            ''
          )}
          <br />
          {showForm && (
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center buy-sto-container">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex flex-row align-items-center justify-content-center justify-content-md-start">
                  <img src={logo_gx} />
                  <h1 className="mb-0">{context.t(`GX Token Flash Sale`)}</h1>
                  <Link
                    to="/GreenX-Presale-Order?ref=FlashPrivateSale"
                    className="buy-sto-btn mt-4 mt-md-0"
                    style={{ marginLeft: '30px' }}>
                    <h2 className="mb-0">{context.t(`BUY`)}</h2>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={layoutClasses('market-overview-container')}>
          <div className={layoutClasses('market-overview-container-left')}>
            <div className={layoutClasses('market-header')}>
              {context.t('Markets Overview')}
            </div>
            <PriceOverview />
            {/*<MarketOverviewComponent />*/}
          </div>
          <div className={layoutClasses('market-overview-container-right')}>
            <div className={layoutClasses('balances-header')}>
              {context.t('Balances')}
            </div>
            <BalancesListContainer />
          </div>
        </div>
        <div className={layoutClasses('recent-activity-container')}>
          <RecentActivityContainer filterForSelected={false} />
        </div>
      </div>
      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

DashboardPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    // get all transfer request in array
    filteredItems: formattedTransfersRequestSelector(state)
  };
};

export default connect(mapStateToProps)(withAuthentication(DashboardPage));
