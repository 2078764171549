import { connect } from 'react-redux';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import SidePaneComponent from './SidePaneComponent';

var mapStateToProps = function mapStateToProps(state) {
  var _state$sidePane = state.sidePane,
    isOpen = _state$sidePane.isOpen,
    name = _state$sidePane.name,
    data = _state$sidePane.data;

  return {
    isOpen: isOpen,
    name: name,
    data: data
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    close: function close(name) {
      return dispatch(closeSidePane(name));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidePaneComponent);
