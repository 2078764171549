import { connect } from 'react-redux';
import { change } from 'redux-form';
import WithdrawTemplateFormComponent from './WithdrawTemplateFormComponent';
import { withdrawProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';

var mapStateToProps = function mapStateToProps(state, _ref) {
  var submitting = _ref.submitting,
      invalid = _ref.invalid,
      onAmountChange = _ref.onAmountChange,
      baseClasses = _ref.baseClasses,
      errorMessage = _ref.errorMessage;
  return {
    template: state.withdraw.template,
    withdrawWorkflowInfo: state.withdraw.withdrawWorkflowInfo,
    product: withdrawProductSelector(state),
    onAmountChange: onAmountChange,
    submitting: submitting,
    invalid: invalid,
    baseClasses: baseClasses,
    errorMessage: errorMessage
  };
};

var mapDispatchToProps = { change: change };

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawTemplateFormComponent);