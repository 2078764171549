import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FiatWithdrawFormComponent from './FiatWithdrawFormComponent';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { fetchProductWithdrawTemplates, fetchProductWithdrawTemplate, createWithdrawTicket } from 'apex-web/lib/redux/actions/withdrawActions';
import { openModal, MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';

var mapStateToProps = function mapStateToProps(state) {
  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
      disableWithdraw = _kycVerificationLevel.disableWithdraw;

  return {
    disableWithdraw: disableWithdraw,
    withdrawInfo: state.withdraw,
    products: state.apexCore.product
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    verificationOnClick: function verificationOnClick() {
      return dispatch(closeSidePane());
    },
    confirmWithdraw: function confirmWithdraw(payload) {
      return dispatch(createWithdrawTicket(payload));
    },
    selectWithdrawProduct: function selectWithdrawProduct(productId) {
      return dispatch(fetchProductWithdrawTemplates(productId));
    },
    selectWithdrawTemplate: function selectWithdrawTemplate(productId, templateType) {
      return dispatch(fetchProductWithdrawTemplate(productId, templateType));
    }
  };
};

var FiatWithdrawForm = reduxForm({
  form: 'createFiatWithdraw',
  onSubmit: function onSubmit(payload, dispatch, _ref) {
    var fee = _ref.fee,
        template = _ref.withdrawInfo.template;

    return dispatch(openModal(MODAL_TYPES.CONFIRM_FIAT_WITHDRAW, { payload: payload, fee: fee, template: template }));
  }
})(FiatWithdrawFormComponent);

export default connect(mapStateToProps, mapDispatchToProps)(FiatWithdrawForm);