import { connect } from 'react-redux';
import DepositReferenceIdModal from './DepositReferenceIdModal';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import config from 'apex-web/lib/config';

var mapStateToProps = function mapStateToProps(state) {
  var _state$deposit$deposi = state.deposit.depositStatus,
      requestcode = _state$deposit$deposi.requestcode,
      referenceid = _state$deposit$deposi.referenceid;


  var referenceCode = config.Deposit.useShortDepositId ? referenceid : requestcode;

  return {
    referenceCode: referenceCode
  };
};

var container = connect(mapStateToProps, null)(DepositReferenceIdModal);

export default modalDecorator({
  name: MODAL_TYPES.DEPOSIT_REFERENCE_ID
})(container);