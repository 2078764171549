/*
 * @Author: your name
 * @Date: 2021-12-03 10:52:12
 * @LastEditTime: 2021-12-03 11:07:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /shadow_v3_firstbullion/src/layouts/StandAloneLayout/StandAloneLayout.js
 */
import React from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APLogo from '../../components/common/APLogo/APLogo';
import './StandAloneLayout.css';
import NavbarOut from '../../components/NewNavOut';

const layoutClasses = getBEMClasses('standalone-layout');

const StandAloneLayout = props => (
  <div className="">
    {/* <NavbarOut /> */}
    {/*
    <APLogo customClass={layoutClasses('logo')} linkTo="/" />
    */}
    {props.children}
  </div>
);

export default StandAloneLayout;
