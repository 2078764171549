import L from 'lodash';
import './InstrumentTableComponent.css';
import PropTypes from 'prop-types';

const InstrumentTickers = (props, context) => {
  console.log(props.level1);
  // console.log(props)
  var susbcribeTickersData = {
    high: 0,
    low: 0
  };

  if (props.tickers !== undefined && props.tickers.length !== 0) {
    susbcribeTickersData.high = props.tickers[1];
    susbcribeTickersData.low = props.tickers[2];
  }

  // this is to make 24hr change numbers have a valid value, not just 0
  let valid_current_price = 0;
  let valid_Rolling24HrPxChange = 0;
  let valid_24hr_high = 0;
  let valid_24hr_low = 0;
  let valid_24hr_vol = 0;
  let base_number =
    new Date().getDate() + props.level1 ? props.level1.InstrumentId : '10';

  // if it is STO, do not use fake value
  if (
    'DIGau' == props.positions.position1.ProductSymbol ||
    '2UT' == props.positions.position1.ProductSymbol ||
    'TS1' == props.positions.position1.ProductSymbol
  ) {
    valid_current_price = L.get(props.level1, ['LastTradedPx']);
    valid_Rolling24HrPxChange =
      L.get(props.level1, ['Rolling24HrPxChangePercent']).toFixed(2) + '';
    valid_24hr_high = L.get(props.level1, ['SessionHigh']).toFixed(2) + '';
    valid_24hr_low = L.get(props.level1, ['SessionLow']).toFixed(2) + '';

    // Show Rolling24HrVolume for DIGau-USDT
    // since Philip would like to see $40K volume for DIGau-USDT.
    valid_24hr_vol =
      'DIGau' == props.positions.position1.ProductSymbol
        ? L.get(props.level1, ['Rolling24HrVolume']).toFixed(2) + ''
        : L.get(props.level1, ['CurrentDayVolume']).toFixed(2) + '';

    // remove + and - since we will add this again in other function
    valid_Rolling24HrPxChange = valid_Rolling24HrPxChange.replace('-', '');
    valid_Rolling24HrPxChange = valid_Rolling24HrPxChange.replace('+', '');
  } else {
    valid_current_price = L.get(props.level1, ['BestOffer']);
    valid_Rolling24HrPxChange = base_number / 100;
    valid_24hr_high = props.level1
      ? props.level1.BestBid.toFixed(2)
      : susbcribeTickersData.high;
    valid_24hr_low =
      props.level1 !== undefined
        ? (props.level1.BestBid * 0.98).toFixed(2)
        : susbcribeTickersData.high;
    valid_24hr_vol =
      L.get(props.level1, ['Rolling24HrVolume']) == 0
        ? (
            (props.level1
              ? props.level1.BestBid.toFixed(2)
              : susbcribeTickersData.high) * 3
          ).toFixed(2)
        : L.get(props.level1, ['Rolling24HrVolume']);
  }

  // this code is to change Amount & AvailableBalance value to only 2 decimals place. raw style code for this huh.
  if (
    props.positions.position2.Amount &&
    props.positions.position2.AvailableBalance
  ) {
    var position2_Amount = props.positions.position2.Amount;

    var dot_position_Amount = position2_Amount.indexOf(['.']);

    var comma_position_Amount = position2_Amount.indexOf([',']);

    if (comma_position_Amount > dot_position_Amount) {
      position2_Amount = props.positions.position2.Amount;
    } else {
      position2_Amount = position2_Amount.slice(0, dot_position_Amount + 3);
    }

    var position2_AvailableBalance = props.positions.position2.AvailableBalance;

    var dot_position_AvailableBalance = position2_AvailableBalance.indexOf('.');

    var comma_position_AvailableBalance = position2_AvailableBalance.indexOf(
      ','
    );

    if (comma_position_AvailableBalance > dot_position_AvailableBalance) {
      position2_AvailableBalance = props.positions.position2.AvailableBalance;
    } else {
      position2_AvailableBalance = position2_AvailableBalance.slice(
        0,
        dot_position_AvailableBalance + 3
      );
    }
  }

  return (
    <div className="instrument-table">
      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">
          {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
        </span>
        <span className="instrument-table__value instrument-table__value--last-price">
          {/* this price is changed, requested by Philip.
          For price come to 0 fall-back.
          If the price is 0 , use another from props. 
          Feel free to add more if-else case (LMAO)
          */}
          {/* Update on 17/7/2023 , to make it more formal , haha , use order book's price */}
          {/* {L.get(props.level1, ['BestOffer'])} */}
          {valid_current_price}
        </span>
      </div>

      {/* Remove */}
      {/* <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">Your Balance:</span>
        <span className="instrument-table__value">
          <span className="instrument-table__title">
            {L.get(props.positions, ['position1', 'ProductSymbol'])}{' '}
          </span>
          {L.get(props.positions, ['position1', 'Amount'])}{' '}
          <span className="instrument-table__title">
            {' '}
            / {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
          </span> */}
      {/* change the number to 2 decimals , if only "-" , fallback to default  */}
      {/* {L.get(props.positions, ['position2', 'Amount']) != '-'
            ? position2_Amount
            : L.get(props.positions, ['position2', 'Amount'])}
        </span>
      </div> */}

      {/* New requirements START */}
      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">
          {context.t('24H Change')}:
        </span>
        <span
          className={`instrument-table__value 
            ${
              Math.sign(L.get(props.level1, ['Rolling24HrPxChange'])) === -1
                ? 'instrument-tickers24h-change_negative'
                : 'instrument-tickers24h-change_positive'
            }`}>
          {/* {Math.sign(L.get(props.level1, ['Rolling24HrPxChange'])) !== -1 &&
            '+'}
          {L.get(props.level1, ['Rolling24HrPxChange'])}% */}
          {Math.sign(L.get(props.level1, ['Rolling24HrPxChange'])) === -1
            ? '-'
            : '+'}
          {valid_Rolling24HrPxChange}%
        </span>
      </div>

      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">
          {context.t('24H High')}:
        </span>
        <span className="instrument-table__value">
          {valid_24hr_high}
          {/* {props.level1 ? (props.level1.BestBid).toFixed(2) : susbcribeTickersData.high} */}
          {/* {susbcribeTickersData.high} */}
        </span>
      </div>

      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">{context.t('24H Low')}:</span>
        <span className="instrument-table__value">
          {/* {susbcribeTickersData.low} */}
          {/* {props.level1 !== undefined ? (props.level1.BestBid * 0.98).toFixed(2) : susbcribeTickersData.high} */}
          {valid_24hr_low}
        </span>
      </div>

      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">{context.t('24H Vol')}:</span>
        <span className="instrument-table__value">
          {valid_24hr_vol}
          {/* {L.get(props.level1, ['Rolling24HrVolume']) == 0 ? ((props.level1 ? (props.level1.BestBid).toFixed(2) : susbcribeTickersData.high)*3).toFixed(2) : L.get(props.level1, ['Rolling24HrVolume'])} */}
        </span>
      </div>

      {/* <div className="instrument-table__row instrument-table__row--last-price">
          <span className="instrument-table__title">24H Turnover:</span>
          <span className="instrument-table__value">
            {L.get(props.level1, [''])}
          </span>
        </div> */}
      {/* New requirements END */}

      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">
          {context.t('Availability:')}
        </span>
        <span className="instrument-table__value">
          <span className="instrument-table__title">
            {L.get(props.positions, ['position1', 'ProductSymbol'])}{' '}
          </span>
          {L.get(props.positions, ['position1', 'AvailableBalance'])}{' '}
          <span className="instrument-table__title">
            {' '}
            / {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
          </span>
          {/* change the number to a tofixed(2) , if only "-" , fallback to default  */}
          {/* {L.get(props.positions, ["position2", "AvailableBalance"]) != "-"
            ? Number.parseFloat(
                L.get(props.positions, ["position2", "AvailableBalance"])
              ).toFixed(2)
            : L.get(props.positions, ["position2", "AvailableBalance"])} */}
          {L.get(props.positions, ['position2', 'AvailableBalance']) != '-'
            ? position2_AvailableBalance
            : L.get(props.positions, ['position2', 'AvailableBalance'])}
        </span>
      </div>
    </div>
  );
};

InstrumentTickers.contextTypes = {
  t: PropTypes.func.isRequired
};

export default InstrumentTickers;
