import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import path from '../helpers/path';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './PurchaseFormPage.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import { connect } from 'react-redux';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';

import logo_009_CQSE from '../pages/img/cloud-digimedia-009.png';
import logo_wenew from '../pages/img/wenew-logo.png';

const layoutClasses = getBEMClasses('purchase-form-page');

const sto_details = {
  '009': {
    title: ' Cloud DigiMedia 009 ',
    image: logo_009_CQSE,
    form_link: 'https://www.green-x.io/presale_form_009',
    session_expiry: {
      '001': {
        start: 'April 25, 2024 19:00:00',
        end: 'April 25, 2024 22:00:00',
        endTimeDisplay: '22:00:00'
      },
      '002': {
        start: 'April 26, 2024 09:00:00',
        end: 'April 26, 2024 11:00:00',
        endTimeDisplay: '11:00:00'
      },
      '003': {
        start: 'April 26, 2024 13:00:00',
        end: 'April 26, 2024 16:00:00',
        endTimeDisplay: '16:00:00'
      },
      testing: {
        start: 'April 25, 2024 00:00:00',
        end: 'May 3, 2024 00:00:00',
        endTimeDisplay: '00:00:00'
      }
    }
  },
  wenew: {
    title: ' WNW RWA ',
    image: logo_wenew,
    form_link: 'https://www.green-x.io/presale_form_wenew',
    session_expiry: {
      '001': {
        start: 'May 19, 2024 12:00:00',
        end: 'May 19, 2024 18:00:00',
        endTimeDisplay: '18:00:00'
      },
      testing: {
        start: 'May 17, 2024 00:00:00',
        end: 'May 19, 2024 18:00:00',
        endTimeDisplay: '18:00:00'
      }
    }
  }
};

const CampaignSignUpPage = (props, context) => {
  const updatelanguageZH = updatedData => {
    const profile = localStorage.getItem('language');
    localStorage.setItem('language', 'zh');
    window.location.reload();
  };

  const updatelanguageEN = updatedData => {
    const profile = localStorage.getItem('language');
    localStorage.setItem('language', 'en');
    window.location.reload();
  };

  var sto = '';
  var source = { url: '', title: 'Presale Order Form', fullUrl: '' };

  const [showForm, setShowForm] = useState(true); // to control show/hide form
  const [sessionStatus, setSessionStatus] = useState({
    datetime: '',
    message: ''
  }); // display respective message
  const [countdown, setCountdown] = useState({}); // countdown to start time
  const [endCountdown, setEndCountdown] = useState({}); // countdown to end time
  const [CampaignStatus, setCampaignStatus] = useState(''); // countdown to end time

  React.useEffect(() => {
    // props.getUserInfo();
    // props.getUserConfig();

    // session validation
    let currentSession = sto_details[sto].session_expiry[sessionID];
    if (currentSession) {
      const targetStartTime = new Date(currentSession.start).getTime();
      const targetEndTime = new Date(currentSession.end).getTime();
      const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        if (currentTime >= targetStartTime && currentTime <= targetEndTime) {
          // show form when between the time range
          setCampaignStatus('after_start');
          setShowForm(true);
          setSessionStatus({
            datetime: '',
            message: ''
          });
        } else {
          // hide form when outside the timerange
          setShowForm(false);

          let sessionDateTimeStr =
            sto_details[sto].session_expiry[sessionID].start +
            ' - ' +
            sto_details[sto].session_expiry[sessionID].endTimeDisplay;
          // display message based on condition
          if (currentTime < targetStartTime) {
            setSessionStatus({
              datetime: sessionDateTimeStr,
              message: true
            });
          } else if (currentTime > targetEndTime) {
            setSessionStatus({
              datetime: sessionDateTimeStr,
              message: false
            });
          }
        }

        if (currentTime >= targetEndTime) {
          setCampaignStatus('end');
        } else if (currentTime <= targetStartTime) {
          setCampaignStatus('before_start');
        }
      }, 500);

      const countdownInterval = setInterval(() => {
        const timeUntilStart = targetStartTime - new Date().getTime();
        if (timeUntilStart <= 0) {
          clearInterval(countdownInterval);
          setCountdown('');
          setSessionStatus({ datetime: '', message: '' });
        } else {
          const days = Math.floor(timeUntilStart / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeUntilStart / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeUntilStart / (1000 * 60)) % 60);
          const seconds = Math.floor((timeUntilStart / 1000) % 60);
          setCountdown({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
          });
        }
      }, 1000);

      const endTimeCountdownInterval = setInterval(() => {
        const timeUntilEnd = targetEndTime - new Date().getTime();
        if (timeUntilEnd <= 0) {
          clearInterval(endTimeCountdownInterval);
          setEndCountdown('');
          setSessionStatus({ datetime: '', message: '' });
        } else {
          const days = Math.floor(timeUntilEnd / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeUntilEnd / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeUntilEnd / (1000 * 60)) % 60);
          const seconds = Math.floor((timeUntilEnd / 1000) % 60);
          setEndCountdown({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
          });
        }
      }, 1000);

      return () => {
        clearInterval(timer);
        clearInterval(countdownInterval);
        clearInterval(endTimeCountdownInterval);
      };
    } else {
      // hide form when session_id is invalid
      setShowForm(false);
      setSessionStatus({ datetime: '', message: 'Invalid Session' });
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  sto = urlParams.get('sto');
  var sessionID = urlParams.get('session_id');

  // set form url and append params
  source.url = sto_details[sto].form_link;
  let user_info = props.user.userInfo;
  source.fullUrl =
    source.url +
    '?username=' +
    user_info.UserName +
    '&email=' +
    user_info.Email +
    '&session_id=' +
    sessionID;

  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />
      <div className="display-message">
        <div className="row" style={{ marginTop: '1rem' }}>
          <div className="col">
            <a
              className="login-form__link"
              style={{ marginLeft: '10px', width: '50px' }}
              onClick={() => updatelanguageZH()}>
              中文
            </a>
          </div>
          <div className="col">
            <span>/</span>
          </div>
          <div className="col">
            <a
              className="login-form__link"
              style={{ marginRight: '10px' }}
              onClick={() => updatelanguageEN()}>
              English
            </a>
          </div>
        </div>
        {sessionStatus.message !== 'Invalid Session' && (
          <>
            <div>
              <img src={sto_details[sto].image} className="sto-logo" />
              <div className="message-text">
                <div className="mb-4">
                  <p>{context.t('Welcome to the ')}</p>
                  <p className="sto-title">{sto_details[sto].title}</p>
                  <p>{context.t('Token Presale Registration')}</p>
                </div>
                <p className="presale-text">
                  {context.t(
                    'The Registration will take place at the following time'
                  )}
                </p>
              </div>
            </div>

            {!showForm ? (
              <>
                <div className="start-time-countdown">
                  <h2 className=" mb-3">
                    {context.t('Duration: ')}
                    {sessionStatus.datetime}
                  </h2>
                  {CampaignStatus == 'end' ||
                  CampaignStatus == 'after_start' ? (
                    ''
                  ) : (
                    <h2 className="mb-0">
                      {context.t('Registration starts in: ')} {countdown.days}{' '}
                      {context.t('Day')} {countdown.hours} {context.t('Hour')}{' '}
                      {countdown.minutes} {context.t('m')} {countdown.seconds}{' '}
                      {context.t('s')}
                    </h2>
                  )}
                </div>

                <h3 className="pt-4 mt-4">
                  {context.t(
                    'REWARDS: Those successfully purchased WNW tokens in the Pre-Sale will received 10 DIGau tokens (~US$20) on GreenX.'
                  )}
                </h3>
              </>
            ) : (
              ''
            )}

            {CampaignStatus == 'after_start' ? (
              <>
                <div className="end-time-countdown">
                  <h2 className="mb-0">
                    {context.t('Registration closes in: ')}
                    {endCountdown.days} {context.t('Day')} {endCountdown.hours}{' '}
                    {context.t('Hour')} {endCountdown.minutes} {context.t('m')}{' '}
                    {endCountdown.seconds} {context.t('s')}
                  </h2>
                </div>
                <div className="message-text" style={{ border: '0px' }}>
                  <Link
                    to={path('/signup?campaign=wenew')}
                    className="theme-btn white-btn with-border"
                    disabled>
                    {context.t('Start Registration')}
                  </Link>
                </div>
              </>
            ) : (
              ''
            )}
          </>
        )}

        <div className="session-message mt-3">
          <h2 className="session-status mt-5">
            {CampaignStatus == 'before_start'
              ? context.t('The Registration has not started yet.')
              : CampaignStatus == 'end'
                ? context.t('The Registration expired.')
                : ''}
          </h2>
          {/* <p className="">Session ID: {sessionID}</p> */}
        </div>
      </div>

      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

CampaignSignUpPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user
  };
};

var mapDispatchToProps = {
  // getUserInfo,
  // getUserConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignSignUpPage);
