import React from 'react';
import PropTypes from 'prop-types';
import APButton from 'apex-web/lib/components/common/APButton';

export var HyphenWithdrawWorkflowComponent = function HyphenWithdrawWorkflowComponent(_ref, context) {
  var productId = _ref.productId,
      templateType = _ref.templateType,
      _ref$template = _ref.template,
      template = _ref$template === undefined ? {} : _ref$template,
      getBankInfoForm = _ref.getBankInfoForm;
  return !Object.keys(template).includes('BankAccountNumber') ? React.createElement(
    APButton,
    {
      onClick: function onClick() {
        return getBankInfoForm(productId, templateType);
      },
      styleName: 'additive' },
    context.t('Edit banking information')
  ) : null;
};

HyphenWithdrawWorkflowComponent.propTypes = {
  productId: PropTypes.number.isRequired,
  templateType: PropTypes.string.isRequired,
  template: PropTypes.object.isRequired,
  getBankInfoForm: PropTypes.func.isRequired
};

HyphenWithdrawWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default HyphenWithdrawWorkflowComponent;