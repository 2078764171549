import React from 'react';
import PropTypes from 'prop-types';
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var FiatWithdrawDetailsComponent = function (_React$Component) {
  _inherits(FiatWithdrawDetailsComponent, _React$Component);

  function FiatWithdrawDetailsComponent() {
    _classCallCheck(this, FiatWithdrawDetailsComponent);

    return _possibleConstructorReturn(this, (FiatWithdrawDetailsComponent.__proto__ || Object.getPrototypeOf(FiatWithdrawDetailsComponent)).apply(this, arguments));
  }

  _createClass(FiatWithdrawDetailsComponent, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          Amount = _props.data.Amount,
          ProductSymbol = _props.product.ProductSymbol,
          balance = _props.balance,
          fee = _props.fee,
          detailsInfo = _props.detailsInfo,
          TicketAmount = _props.TicketAmount;
      var getItem = SidePaneDetailsComponent.getItem;

      var currentBalance = balance ? formatNumberToLocale(balance, ProductSymbol) + ' ' + ProductSymbol : '-';
      var amount = TicketAmount ? formatNumberToLocale(TicketAmount, ProductSymbol) + ' ' + ProductSymbol : '-';

      /**
       * TicketAmount = `GetWithdrawFee`.TicketAmount.
       *  If (Gateway.`DeductWithdrawFee` = true), fee is already deducted.
       */
      var remainingBalance = balance && Amount ? formatNumberToLocale(balance - (Amount === TicketAmount ? TicketAmount : TicketAmount + fee), ProductSymbol) + ' ' + ProductSymbol : '-';
      var items = [getItem(this.context.t('Your current {ProductSymbol} Balance', {
        ProductSymbol: ProductSymbol
      }), currentBalance), getItem(this.context.t('Amount to Withdraw'), amount), {
        label: this.context.t('Fee'),
        text: fee ? formatNumberToLocale(fee, ProductSymbol) + ' ' + ProductSymbol : '-'
      }, getItem(this.context.t('Remaining Balance'), remainingBalance)];

      var info = detailsInfo.map(function (item) {
        if (item.useLink) {
          return React.createElement(
            'a',
            { href: item.linkAddress },
            _this2.context.t('{text}', { text: item.text })
          );
        } else {
          return _this2.context.t('{text}', { text: item.text });
        }
      });

      return React.createElement(SidePaneDetailsComponent, {
        title: this.context.t('Balances'),
        items: items,
        info: info,
        classModifiers: 'withdraw'
      });
    }
  }]);

  return FiatWithdrawDetailsComponent;
}(React.Component);

FiatWithdrawDetailsComponent.propTypes = {
  FullName: PropTypes.string,
  Amount: PropTypes.number,
  BankAccountName: PropTypes.string,
  BankAccountNumber: PropTypes.string,
  BankAddress: PropTypes.string,
  Comment: PropTypes.string
};

FiatWithdrawDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatWithdrawDetailsComponent;