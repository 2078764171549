import React from 'react';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import 'apex-web/lib/layouts/Responsive/EotcBuySellLayout/EotcBuySellDesktopLayout.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import CardImg from '../images/custom/cards.png';
import ErrorImg from '../images/custom/error.webp';
import './JoomioPage.css';
import PropTypes from 'prop-types';

const classes = getBEMClasses('eotc-buy-sell-layout');

const ErrorPage = (props, context) => (
  // <React.Fragment>
  //   <div>
  //     <div className={classes('accent-bar')} />
  //     <div className={classes('container')}>
  //       <div className={classes('col', ['lg'])}>
  //         <div className="title-container">
  //           <div className="col-left">
  //             <h2>Card Purchase - Error</h2>
  //           </div>
  //           {/* <div className="col-right">
  //             <img className="card-img" src={CardImg} />
  //           </div> */}
  //         </div>
  //         <div className="cp-container-dark">
  //           <img className="cp-success-img" src={ErrorImg} />
  //           <h1 className="cp-success-header">Order was unsuccessful!</h1>
  //           <h3 className="cp-success-text">
  //             We have encountered a problem with your credit card purchase,
  //             please try again or contact support at support@greenx.io.
  //           </h3>
  //         </div>
  //       </div>
  //     </div>
  //     <div className={classes('footer')}>
  //       <PageFooterComponent />
  //     </div>
  //   </div>
  // </React.Fragment>
  <React.Fragment>
    <div>
      <div className={classes('accent-bar')} />
      <div className={classes('container')}>
        <div className={classes('col', ['lg'])}>
          <div className="title-container">
            <div className="col-left">
              <h2>{context.t('Trade Reports - Error')}</h2>
            </div>
          </div>
          <div className="cp-container-dark error-page_container">
            <img className="cp-success-img" src={ErrorImg} />
            <h1 className="cp-success-header error-page_text-header">
              {context.t('Sorry, something went wrong')}
            </h1>
            <h3 className="cp-success-text error-page_text-content">
              {context.t(
                'We have encountered a problem with the Trade Report features and we are currently working on the fixes.'
              )}
            </h3>
            <h3 className="cp-success-text error-page_text-content">
              {context.t('Please come back and try again later.')}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

ErrorPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default withAuthentication(ErrorPage);
